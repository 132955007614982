<template>
    <Card class="OverviewCard LoginCard">
        <div class="metric-content">
            <div class="metric-icon negative">
                <i class="ri-error-warning-line"></i>
            </div>
            <div class="metric-details">
                <h3>Falschanmeldungen</h3>
                <div class="metric-value">{{ logins.length }}</div>
            </div>
        </div>
        <Button size="small" @onClick="$emit('onEditClick')" class="action-button">
            Bearbeiten
        </Button>
    </Card>
</template>

<script>
import Card from '@/components/Card';
import Button from '@/components/widgets/Button';

export default {
    name: 'LoginIssues',
    components: {
        Card,
        Button,
    },
    props: {
        logins: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss">
@import './OverviewCard.scss';

.LoginCard {
    .metric-icon {
        background-color: rgba(var(--color-red-rgb), 0.1);
        color: var(--color-red);
    }

    .action-button {
        margin-top: 12px;
        width: 100%;
    }
}
</style>
