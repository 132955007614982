var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', {
    staticClass: "SupportCard"
  }, [_c('h3', {
    staticClass: "SupportCard-title"
  }, [_c('i', {
    staticClass: "ri-customer-service-2-line"
  }), _vm._v(" Technischer Support ")]), _c('div', {
    staticClass: "SupportCard-content"
  }, [_c('div', {
    staticClass: "SupportCard-section"
  }, [_c('div', {
    staticClass: "section-header"
  }, [_c('i', {
    staticClass: "ri-phone-fill section-icon"
  }), _c('h4', [_vm._v("Telefon-Support")])]), _c('div', {
    staticClass: "support-contact-row"
  }, [_c('div', {
    staticClass: "phone-display"
  }, [_vm._v("+49 30 76 00 65 05")]), _c('a', {
    staticClass: "action-button call-button",
    attrs: {
      "href": "tel:+493076006505"
    }
  }, [_c('i', {
    staticClass: "ri-phone-line"
  }), _vm._v(" Anrufen ")])]), _c('div', {
    staticClass: "hours-grid"
  }, [_c('div', {
    staticClass: "day-column"
  }, [_c('i', {
    staticClass: "ri-calendar-line"
  }), _vm._v(" Di "), _c('div', {
    staticClass: "time"
  }, [_vm._v("14-16 Uhr")])]), _c('div', {
    staticClass: "day-column"
  }, [_c('i', {
    staticClass: "ri-calendar-line"
  }), _vm._v(" Do "), _c('div', {
    staticClass: "time"
  }, [_vm._v("16-19 Uhr")])])])]), _c('div', {
    staticClass: "SupportCard-divider"
  }, [_c('span', [_vm._v("ODER")])]), _c('div', {
    staticClass: "SupportCard-section"
  }, [_c('div', {
    staticClass: "section-header"
  }, [_c('i', {
    staticClass: "ri-mail-fill section-icon"
  }), _c('h4', [_vm._v("Email-Support")])]), _c('div', {
    staticClass: "support-contact-row"
  }, [_c('div', {
    staticClass: "email-display"
  }, [_vm._v("info@taxifusion.de")]), _c('a', {
    staticClass: "action-button email-button",
    attrs: {
      "href": "mailto:info@taxifusion.de"
    }
  }, [_c('i', {
    staticClass: "ri-mail-send-line"
  }), _vm._v(" Email ")])]), _c('div', {
    staticClass: "time-response"
  }, [_c('i', {
    staticClass: "ri-time-line"
  }), _vm._v(" Antwort in 2 Werktagen")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }