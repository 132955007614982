<template>
    <Card class="OverviewCard DriverCard">
        <div class="metric-content">
            <div class="metric-icon">
                <i class="ri-user-line"></i>
            </div>
            <div class="metric-details">
                <h3>{{ title }}</h3>
                <div class="metric-value">
                    {{ driver?.name || 'N/A' }}
                </div>
                <div class=" metric-change positive">
                    {{ priceToEuroString(driver?.shiftSums?.income) }}
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from '@/components/Card';
import { priceToEuroString, distanceToString } from '@/lib/helper';

export default {
    name: 'DriverPerformance',
    components: { Card },
    props: {
        driver: {
            type: Object,
            default: () => ({}),
        },
        title: {
            type: String,
            default: 'Fahrer',
        },
    },
    methods: {
        priceToEuroString,
        distanceToString,
    },
};
</script>

<style lang="scss">
@import './OverviewCard.scss';

.DriverCard {
    .metric-icon {
        background-color: rgba(var(--color-blue-rgb), 0.1);
        color: var(--color-blue);
    }
}
</style>
