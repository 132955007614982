var render = function () {
  var _vm$vehicle, _vm$vehicle2, _vm$vehicle2$shiftSum;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', {
    staticClass: "OverviewCard VehicleCard"
  }, [_c('div', {
    staticClass: "metric-content"
  }, [_c('div', {
    staticClass: "metric-icon"
  }, [_c('i', {
    staticClass: "ri-taxi-line"
  })]), _c('div', {
    staticClass: "metric-details"
  }, [_c('h3', [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "metric-value"
  }, [_vm._v(_vm._s(((_vm$vehicle = _vm.vehicle) === null || _vm$vehicle === void 0 ? void 0 : _vm$vehicle.licenseNumber) || 'N/A'))]), _c('div', {
    staticClass: "metric-change positive"
  }, [_vm._v(" " + _vm._s(_vm.priceToEuroString((_vm$vehicle2 = _vm.vehicle) === null || _vm$vehicle2 === void 0 ? void 0 : (_vm$vehicle2$shiftSum = _vm$vehicle2.shiftSums) === null || _vm$vehicle2$shiftSum === void 0 ? void 0 : _vm$vehicle2$shiftSum.income)) + " ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }