var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', {
    staticClass: "OverviewCard LoginCard"
  }, [_c('div', {
    staticClass: "metric-content"
  }, [_c('div', {
    staticClass: "metric-icon negative"
  }, [_c('i', {
    staticClass: "ri-error-warning-line"
  })]), _c('div', {
    staticClass: "metric-details"
  }, [_c('h3', [_vm._v("Falschanmeldungen")]), _c('div', {
    staticClass: "metric-value"
  }, [_vm._v(_vm._s(_vm.logins.length))])])]), _c('Button', {
    staticClass: "action-button",
    attrs: {
      "size": "small"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.$emit('onEditClick');
      }
    }
  }, [_vm._v(" Bearbeiten ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }