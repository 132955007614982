<template>
    <Card class="PerformanceCard">
        <h3 class="PerformanceCard-title">{{ name }}</h3>

        <div class="PerformanceCard-content">
            <!-- Main KPI section with progress bar and €/Km value -->
            <div class="PerformanceCard-main-kpi">
                <div class="kpi-label">Leistung</div>
                <div class="kpi-value-container">
                    <div
                        class="kpi-value-wrapper"
                        :class="[`performance-${performanceData.class}`]"
                    >
                        <span class="kpi-value">{{ performanceData.euroPerKm }}</span>
                        <span class="kpi-unit">€/Km</span>
                    </div>
                    <div class="kpi-status" :class="[`status-${performanceData.class}`]">
                        {{ performanceData.ratingText }}
                    </div>
                </div>
                <div class="custom-progress-bar">
                    <div
                        class="progress-fill"
                        :style="{ width: `${performanceData.percentage}%` }"
                        :class="[`progress-${performanceData.class}`]"
                    ></div>
                    <div class="progress-markers">
                        <div class="marker marker-low"></div>
                        <div class="marker marker-mid"></div>
                        <div class="marker marker-high"></div>
                    </div>
                </div>
            </div>

            <!-- Compact metrics layout -->
            <div class="metrics-layout">
                <!-- Financial metrics -->
                <div class="metrics-group">
                    <div class="metrics-group-title">Finanzen</div>
                    <div class="metrics-row">
                        <span class="metric-label">Einnahmen:</span>
                        <strong class="metric-value">{{
                            priceToEuroString(item.income || 0)
                        }}</strong>
                    </div>
                </div>

                <!-- Distance metrics -->
                <div class="metrics-group">
                    <div class="metrics-group-title">Strecke</div>
                    <div class="metrics-row">
                        <span class="metric-label">Gesamt-Km:</span>
                        <strong class="metric-value">{{
                            distanceToString(item.totalDistance || 0)
                        }}</strong>
                    </div>
                    <div class="metrics-row">
                        <span class="metric-label">Besetzt-Km:</span>
                        <strong class="metric-value">{{
                            distanceToString(item.hiredDistance || 0)
                        }}</strong>
                    </div>
                    <div class="metrics-row">
                        <span class="metric-label">Besetzt-%:</span>
                        <strong class="metric-value">
                            {{
                                percentageToString(
                                    (item.hiredDistance || 0) / (item.totalDistance || 1),
                                )
                            }}
                        </strong>
                    </div>
                </div>

                <!-- Time metrics -->
                <div class="metrics-group">
                    <div class="metrics-group-title">Zeit</div>
                    <div class="metrics-row">
                        <span class="metric-label">Schichtzeit:</span>
                        <strong class="metric-value">{{
                            timeToString(item.shiftTime || 0)
                        }}</strong>
                    </div>
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from '@/components/Card';
import {
    priceToEuroString,
    distanceToString,
    percentageToString,
    timeToString,
} from '@/lib/helper';

export default {
    name: 'PerformanceDetails',
    components: {
        Card,
    },
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        name: {
            type: String,
            default: 'Nicht ausgewählt',
        },
    },
    computed: {
        performanceData() {
            const income = this.item.income || 0;
            const distance = this.item.totalDistance || 1;
            const performance = Number(((income * 10) / distance).toFixed(2));
            const percentage = Math.min((performance / 2) * 100, 100);

            // Classes and text for styling based on performance
            let performanceClass;
            let ratingText;

            if (performance > 1.39) {
                performanceClass = 'excellent';
                ratingText = 'Ausgezeichnet';
            } else if (performance > 1.19) {
                performanceClass = 'good';
                ratingText = 'Gut';
            } else if (performance > 0.9) {
                performanceClass = 'average';
                ratingText = 'Durchschnitt';
            } else {
                performanceClass = 'poor';
                ratingText = 'Verbesserungsbedarf';
            }

            return {
                euroPerKm: performance.toFixed(2),
                percentage: percentage,
                class: performanceClass,
                ratingText,
            };
        },
    },
    methods: {
        priceToEuroString,
        distanceToString,
        percentageToString,
        timeToString,
    },
};
</script>

<style lang="scss">
.PerformanceCard {
    padding: 6px;
    width: 100%;
    font-size: 0.85rem;

    &-title {
        margin: 0 0 6px 0;
        padding: 5px 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--color-text-blue-dark, #2c3e50);
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &-main-kpi {
        background-color: rgba(0, 0, 0, 0.01);
        padding: 8px;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        .kpi-label {
            font-size: 0.75rem;
            color: var(--color-gray, #95a5a6);
            text-transform: uppercase;
            letter-spacing: 0.5px;
        }

        .kpi-value-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .kpi-value-wrapper {
            display: flex;
            align-items: baseline;
        }

        .kpi-value {
            font-size: 1.5rem;
            font-weight: 700;
            font-feature-settings: 'tnum';
            font-variant-numeric: tabular-nums;
        }

        .kpi-unit {
            margin-left: 4px;
            font-size: 0.8rem;
            color: var(--color-gray, #95a5a6);
        }

        .kpi-status {
            font-size: 0.75rem;
            font-weight: 600;
            padding: 3px 8px;
            border-radius: 12px;
            background-color: rgba(0, 0, 0, 0.05);
        }

        .status-excellent {
            background-color: rgba(30, 130, 76, 0.1);
            color: #1e824c;
        }

        .status-good {
            background-color: rgba(39, 174, 96, 0.1);
            color: #27ae60;
        }

        .status-average {
            background-color: rgba(243, 156, 18, 0.1);
            color: #f39c12;
        }

        .status-poor {
            background-color: rgba(231, 76, 60, 0.1);
            color: #e74c3c;
        }

        .custom-progress-bar {
            position: relative;
            height: 6px;
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 3px;
            overflow: hidden;
            margin-top: 2px;

            .progress-fill {
                height: 100%;
                border-radius: 3px;
                transition: width 0.5s ease;
            }

            .progress-markers {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                padding: 0 25%;
                pointer-events: none;

                .marker {
                    width: 1px;
                    height: 6px;
                    background-color: rgba(255, 255, 255, 0.5);
                }
            }

            .progress-excellent {
                background-color: #1e824c;
                background-image: linear-gradient(
                    45deg,
                    rgba(255, 255, 255, 0.15) 25%,
                    transparent 25%,
                    transparent 50%,
                    rgba(255, 255, 255, 0.15) 50%,
                    rgba(255, 255, 255, 0.15) 75%,
                    transparent 75%,
                    transparent
                );
                background-size: 20px 20px;
                animation: progress-bar-stripes 1s linear infinite;
            }

            .progress-good {
                background-color: #27ae60;
            }

            .progress-average {
                background-color: #f39c12;
            }

            .progress-poor {
                background-color: #e74c3c;
            }

            @keyframes progress-bar-stripes {
                from {
                    background-position: 20px 0;
                }
                to {
                    background-position: 0 0;
                }
            }
        }
    }

    .metrics-layout {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    .metrics-group {
        border-radius: 4px;
        padding: 6px 8px;

        &-title {
            font-size: 0.75rem;
            color: var(--color-gray, #95a5a6);
            text-transform: uppercase;
            letter-spacing: 0.5px;
            margin-bottom: 3px;
        }
    }

    .metrics-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1.4;
    }

    .metric-label {
        font-size: 0.8rem;
        color: var(--color-text-black, #333);
    }

    .metric-value {
        font-size: 0.8rem;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--color-text-black, #333);
        font-feature-settings: 'tnum';
        font-variant-numeric: tabular-nums;
    }

    // Performance color indicators
    .performance-excellent {
        color: #1e824c;
    }

    .performance-good {
        color: #27ae60;
    }

    .performance-average {
        color: #f39c12;
    }

    .performance-poor {
        color: #e74c3c;
    }

    // Responsive adjustments
    @media (max-width: 480px) {
        &-main-kpi {
            .kpi-value {
                font-size: 1.3rem;
            }
        }
    }
}
</style>
