var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.show ? _c('div', {
    staticClass: "LoadingOverlay"
  }, [_c('div', {
    staticClass: "LoadingOverlay-content"
  }, [_c('div', {
    staticClass: "LoadingOverlay-spinner"
  }), _c('p', [_vm._v(_vm._s(_vm.message))])])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }