var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', {
    staticClass: "EventsCalendarCard"
  }, [_c('h3', {
    staticClass: "EventsCalendarCard-title"
  }, [_vm._v("Anstehende Fahrten")]), _c('TaxiCalendarWidget', {
    attrs: {
      "events": _vm.upcomingEvents
    },
    on: {
      "on-add-event": _vm.handleAddEvent,
      "on-edit-event": _vm.handleEditEvent,
      "on-cancel-event": _vm.handleCancelEvent,
      "on-click-item": _vm.handleEventClick
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }