<template>
    <Card class="OverviewCard RevenueTarget">
        <div class="metric-content">
            <div class="metric-icon" :class="getProgressClass()">
                <i class="ri-funds-line"></i>
            </div>
            <div class="metric-details">
                <h3>Monatliches Ziel</h3>
                <div class="metric-value">{{ priceToEuroString(monthlyIncome) }}</div>
                <div class="metric-change" :class="getProgressClass()">
                    <span
                        >{{
                            percentageToString(monthlyIncome / (targetIncome * 100))
                        }}
                        erreicht</span
                    >
                    <i class="trend-icon ri-arrow-up-line"></i>
                </div>
            </div>
            <div class="settings-icon" @click="$emit('onSettingsClick')">
                <i class="ri-settings-line"></i>
            </div>
        </div>
        <!-- Hidden progress bar (optional) -->
        <div class="progress-container">
            <div class="progress-bar" :style="{ width: `${getProgressPercentage()}%` }"></div>
            <div class="progress-target">
                <span>Ziel: {{ priceToEuroString(targetIncome * 100) }}</span>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from '@/components/Card';
import { priceToEuroString, percentageToString } from '@/lib/helper';

export default {
    name: 'RevenueTarget',
    components: {
        Card,
    },
    props: {
        monthlyIncome: {
            type: Number,
            required: true,
        },
        targetIncome: {
            type: Number,
            required: true,
        },
    },
    methods: {
        priceToEuroString,
        percentageToString,
        getProgressPercentage() {
            // Calculate percentage toward target, capped at 100%
            return Math.min(100, (this.monthlyIncome / (this.targetIncome * 100)) * 100);
        },
        getProgressClass() {
            const percentage = this.monthlyIncome / (this.targetIncome * 100);
            if (percentage >= 1) return 'positive';
            if (percentage >= 0.75) return 'neutral';
            return 'negative';
        },
    },
};
</script>

<style lang="scss">
@import './OverviewCard.scss';

.RevenueTarget {
    .metric-content {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        position: relative;
    }

    .metric-icon {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        background-color: rgba(var(--color-green-rgb), 0.1);
        color: var(--color-green-dark);

        &.positive {
            background-color: rgba(75, 181, 67, 0.1);
            color: var(--color-green-dark);
        }

        &.negative {
            background-color: rgba(242, 78, 30, 0.1);
            color: var(--color-red);
        }
    }

    .metric-details {
        flex: 1;

        h3 {
            font-size: 14px;
            margin: 0 0 4px 0;
            font-weight: normal;
            color: var(--color-gray);
        }

        .metric-value {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 4px;
            color: var(--color-text-black);
        }

        .metric-change {
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 12px;

            &.positive {
                color: var(--color-green-dark);

                .trend-icon {
                    transform: rotate(0deg);
                }
            }

            &.negative {
                color: var(--color-red);

                .trend-icon {
                    transform: rotate(180deg);
                }
            }

            &.neutral {
                color: var(--color-yellow);

                .trend-icon {
                    display: none;
                }
            }
        }
    }

    .settings-icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: var(--color-text-blue);
        transition: transform 0.2s ease;

        &:hover {
            transform: rotate(30deg);
        }
    }

    .progress-container {
        margin-top: 12px;
        height: 6px;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 3px;
        position: relative;
        overflow: hidden;

        .progress-bar {
            height: 100%;
            background-color: var(--color-green-dark);
            border-radius: 3px;
        }

        .progress-target {
            margin-top: 4px;
            font-size: 12px;
            text-align: right;
            color: var(--color-gray);
        }
    }
}
</style>
