<template>
    <Modal
        title="Umsatzziel ändern"
        :show="show"
        :action="{ text: 'Speichern', color: 'green', callback: handleSave }"
        :cancel="{ text: 'Abbrechen' }"
        :isLoading="isLoading"
        @onModalClose="$emit('onClose')"
    >
        <div class="RevenueGoalModal">
            <p class="RevenueGoalModal-description">
                Bitte geben Sie den neuen Umsatz ein, den Sie erreichen möchten (in Euro) pro Monat.
            </p>

            <div class="RevenueGoalModal-input">
                <label for="targetIncome">Umsatzziel (in Euro)</label>
                <Input
                    id="targetIncome"
                    type="number"
                    keyName="targetIncome"
                    :value="targetIncome"
                    @onKeyPress="handleChange"
                />
            </div>

            <div class="RevenueGoalModal-preview">
                <div class="RevenueGoalModal-preview-label">Vorschau:</div>
                <div class="RevenueGoalModal-preview-amount">
                    {{ formattedTargetIncome }}
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@/components/widgets/Modal';
import Input from '@/components/widgets/Input';
import { priceToEuroString } from '@/lib/helper';

export default {
    name: 'RevenueGoalModal',
    components: {
        Modal,
        Input,
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        targetIncome: {
            type: Number,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        formattedTargetIncome() {
            return priceToEuroString(this.targetIncome * 100);
        },
    },
    methods: {
        handleChange(data) {
            this.$emit('onChange', data);
        },
        handleSave() {
            this.$emit('onSave');
        },
    },
};
</script>

<style lang="scss">
.RevenueGoalModal {
    &-description {
        margin-bottom: 24px;
        line-height: 1.5;
    }

    &-input {
        margin-bottom: 24px;

        label {
            display: block;
            font-weight: 600;
            margin-bottom: 8px;
        }
    }

    &-preview {
        background-color: #f8f9fa;
        padding: 16px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-label {
            font-weight: 600;
            color: var(--color-gray);
        }

        &-amount {
            font-size: 1.25rem;
            font-weight: 700;
            color: var(--color-green-dark);
        }
    }
}
</style>
