<template>
    <Card class="EventsCalendarCard">
        <h3 class="EventsCalendarCard-title">Anstehende Fahrten</h3>
        <TaxiCalendarWidget
            :events="upcomingEvents"
            @on-add-event="handleAddEvent"
            @on-edit-event="handleEditEvent"
            @on-cancel-event="handleCancelEvent"
            @on-click-item="handleEventClick"
        />
    </Card>
</template>

<script>
import Card from '@/components/Card.vue';
import TaxiCalendarWidget from '@/components/widgets/Calendar.vue';

export default {
    name: 'EventsCalendarCard',
    components: {
        Card,
        TaxiCalendarWidget,
    },
    props: {
        events: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        upcomingEvents() {
            return this.events.map(event => ({
                ...event,
                // These properties are required by the calendar component
                id: event.id,
                title: event.title || `${event.start || ''} → ${event.destination || ''}`,
                startDate: new Date(event.startDate),
                endDate: event.endDate ? new Date(event.endDate) : null,
                class: event.type, // For coloring (reservation, airport, scheduled, maintenance)
                // Add any additional formatting needed
            }));
        },
    },
    methods: {
        handleAddEvent(data) {
            this.$emit('on-add-event', data);
        },

        handleEditEvent(event) {
            this.$emit('on-edit-event', event);
        },

        handleCancelEvent(event) {
            this.$emit('on-cancel-event', event);
        },

        handleEventClick(event) {
            this.$emit('on-click-event', event);
        },
    },
};
</script>

<style lang="scss">
.EventsCalendarCard {
    grid-column: span 2;
    padding: 0;
    overflow: hidden;

    &-title {
        font-size: 1rem;
        font-weight: 600;
        margin: 0;
        padding: 16px 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        background-color: #fafafa;
    }

    // Calendar height adjustment for dashboard
    .TaxiCalendar {
        height: 600px;

        .taxi-calendar-view {
            height: 450px;
        }

        @media (max-width: breakpoint(tabletPortrait)) {
            height: auto;

            .taxi-calendar-view {
                height: auto;
            }
        }
    }
}
</style>
