<template>
    <Card class="CalendarWidget TaxiCalendar">
        <div class="TaxiCalendar-header">
            <h2>Taxi Einsatzplanung</h2>
            <div class="TaxiCalendar-controls">
                <div class="TaxiCalendar-legends">
                    <div class="LegendItem">
                        <span class="ColorDot reservation"></span>
                        <span>Reservierung</span>
                    </div>
                    <div class="LegendItem">
                        <span class="ColorDot airport"></span>
                        <span>Flughafen</span>
                    </div>
                    <div class="LegendItem">
                        <span class="ColorDot scheduled"></span>
                        <span>Regelmäßige Fahrt</span>
                    </div>
                    <div class="LegendItem">
                        <span class="ColorDot maintenance"></span>
                        <span>Wartung</span>
                    </div>
                </div>
                <div class="TaxiCalendar-actions">
                    <Button size="small" variant="outline" @onClick="handleAddEvent" icon="plus">
                        Neuer Eintrag
                    </Button>
                    <Button size="small" variant="outline" @onClick="handleViewMode" icon="th">
                        {{ viewMode === 'month' ? 'Wochenansicht' : 'Monatsansicht' }}
                    </Button>
                </div>
            </div>
        </div>

        <div class="TaxiCalendar-filters">
            <div class="FilterGroup">
                <label>Fahrer:</label>
                <Dropdown
                    size="small"
                    variant="minimal"
                    :items="drivers"
                    :selected="selectedDriver"
                    @onItemSelect="handleDriverSelect"
                    placeholder="Alle Fahrer"
                />
            </div>
            <div class="FilterGroup">
                <label>Fahrzeug:</label>
                <Dropdown
                    size="small"
                    variant="minimal"
                    :items="vehicles"
                    :selected="selectedVehicle"
                    @onItemSelect="handleVehicleSelect"
                    placeholder="Alle Fahrzeuge"
                />
            </div>
            <div class="FilterGroup">
                <label>Kategorie:</label>
                <Dropdown
                    size="small"
                    variant="minimal"
                    :items="categories"
                    :selected="selectedCategory"
                    @onItemSelect="handleCategorySelect"
                    placeholder="Alle Kategorien"
                />
            </div>
        </div>

        <calendar-view
            :enableDragDrop="true"
            :enable-date-selection="true"
            :items="filteredEvents"
            :showDate="dateToShow"
            :selection-start="selectionStart"
            :selection-end="selectionEnd"
            :displayPeriodUom="viewMode"
            :time-from-string="timeFromString"
            :time-to-string="timeToString"
            class="theme-default taxi-calendar-view"
            locale="de"
            @date-selection-finish="handleDateSelection"
            @date-selection-start="setSelection"
            @date-selection="setSelection"
            @click-date="handleClickDate"
            @click-item="handleClickItem"
            @drop-on-date="handleDropOnDate"
        >
            <calendar-view-header
                slot="header"
                slot-scope="t"
                :header-props="t.headerProps"
                @input="setShowDate"
            ></calendar-view-header>
        </calendar-view>

        <div class="TaxiCalendar-info" v-if="selectedEvent">
            <div class="InfoCard">
                <div class="InfoHeader">
                    <h3>{{ selectedEvent.title }}</h3>
                    <button class="CloseButton" @click="selectedEvent = null">&times;</button>
                </div>
                <div class="InfoDetails">
                    <div class="InfoRow">
                        <span class="InfoLabel">Datum:</span>
                        <span class="InfoValue">{{ formatEventDate(selectedEvent) }}</span>
                    </div>
                    <div class="InfoRow">
                        <span class="InfoLabel">Zeit:</span>
                        <span class="InfoValue">{{ formatEventTime(selectedEvent) }}</span>
                    </div>
                    <div class="InfoRow">
                        <span class="InfoLabel">Fahrer:</span>
                        <span class="InfoValue">{{ selectedEvent.driver || '-' }}</span>
                    </div>
                    <div class="InfoRow">
                        <span class="InfoLabel">Fahrzeug:</span>
                        <span class="InfoValue">{{ selectedEvent.vehicle || '-' }}</span>
                    </div>
                    <div class="InfoRow">
                        <span class="InfoLabel">Start:</span>
                        <span class="InfoValue">{{ selectedEvent.start || '-' }}</span>
                    </div>
                    <div class="InfoRow">
                        <span class="InfoLabel">Ziel:</span>
                        <span class="InfoValue">{{ selectedEvent.destination || '-' }}</span>
                    </div>
                    <div class="InfoRow">
                        <span class="InfoLabel">Kunde:</span>
                        <span class="InfoValue">{{ selectedEvent.customer || '-' }}</span>
                    </div>
                    <div class="InfoRow">
                        <span class="InfoLabel">Kontakt:</span>
                        <span class="InfoValue">{{ selectedEvent.contact || '-' }}</span>
                    </div>
                    <div class="InfoRow" v-if="selectedEvent.notes">
                        <span class="InfoLabel">Notizen:</span>
                        <span class="InfoValue">{{ selectedEvent.notes }}</span>
                    </div>
                </div>
                <div class="InfoActions">
                    <Button size="small" variant="outline" @onClick="handleEditEvent" icon="edit">
                        Bearbeiten
                    </Button>
                    <Button
                        size="small"
                        variant="outline"
                        color="red"
                        @onClick="handleCancelEvent"
                        icon="times"
                    >
                        Stornieren
                    </Button>
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar';
import Card from '../Card.vue';
import Button from '../widgets/Button.vue';
import Dropdown from '../widgets/Dropdown.vue';
import { format, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';

require('vue-simple-calendar/static/css/default.css');

export default {
    name: 'TaxiCalendarWidget',
    props: {
        events: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            dateToShow: new Date(),
            selectionStart: null,
            selectionEnd: null,
            viewMode: 'month', // 'month' or 'week'
            selectedEvent: null,
            selectedDriver: null,
            selectedVehicle: null,
            selectedCategory: null,
            timeFromString: 'HH:mm', // Time format for display
            timeToString: 'HH:mm', // Time format for display
            drivers: [
                { id: 'driver1', value: 'Max Mustermann' },
                { id: 'driver2', value: 'Anna Schmidt' },
                { id: 'driver3', value: 'Thomas Weber' },
                { id: 'driver4', value: 'Lisa Müller' },
            ],
            vehicles: [
                { id: 'car1', value: 'Mercedes E-Klasse (T-MB-1234)' },
                { id: 'car2', value: 'VW Passat (T-VW-5678)' },
                { id: 'car3', value: 'BMW 5er (T-BM-9101)' },
                { id: 'car4', value: 'Audi A6 (T-AU-1213)' },
            ],
            categories: [
                { id: 'reservation', value: 'Reservierung' },
                { id: 'airport', value: 'Flughafen' },
                { id: 'scheduled', value: 'Regelmäßige Fahrt' },
                { id: 'maintenance', value: 'Wartung' },
            ],
        };
    },
    components: {
        CalendarView,
        CalendarViewHeader,
        Card,
        Button,
        Dropdown,
    },
    computed: {
        filteredEvents() {
            let result = [...this.events];

            if (this.selectedDriver) {
                result = result.filter(event => event.driver === this.selectedDriver.value);
            }

            if (this.selectedVehicle) {
                result = result.filter(event => event.vehicle === this.selectedVehicle.value);
            }

            if (this.selectedCategory) {
                result = result.filter(event => event.class === this.selectedCategory.id);
            }

            return result;
        },
    },
    methods: {
        formatEventDate(event) {
            if (!event.startDate) return '-';
            return format(new Date(event.startDate), 'EEEE, dd. MMMM yyyy', { locale: de });
        },

        formatEventTime(event) {
            if (!event.startDate) return '-';
            const start = format(new Date(event.startDate), 'HH:mm');
            const end = event.endDate ? format(new Date(event.endDate), 'HH:mm') : '-';
            return `${start} - ${end}`;
        },

        handleDriverSelect({ item }) {
            this.selectedDriver = item;
        },

        handleVehicleSelect({ item }) {
            this.selectedVehicle = item;
        },

        handleCategorySelect({ item }) {
            this.selectedCategory = item;
        },

        handleViewMode() {
            this.viewMode = this.viewMode === 'month' ? 'week' : 'month';
        },

        handleAddEvent() {
            this.$emit('on-add-event', {
                date: this.selectionStart || new Date(),
            });
        },

        handleEditEvent() {
            this.$emit('on-edit-event', this.selectedEvent);
            this.selectedEvent = null;
        },

        handleCancelEvent() {
            if (confirm('Sind Sie sicher, dass Sie diese Fahrt stornieren möchten?')) {
                this.$emit('on-cancel-event', this.selectedEvent);
                this.selectedEvent = null;
            }
        },

        setSelection(props) {
            this.selectionStart = props[0];
            this.selectionEnd = props[1];
            this.$emit('on-date-selection', { startDate: props[0], endDate: props[1] });
        },

        setShowDate(date) {
            this.dateToShow = date;
        },

        handleClickDate(date) {
            this.selectedEvent = null;
            this.$emit('on-click-date', date);
        },

        handleClickItem(item) {
            this.selectedEvent = item;
            this.$emit('on-click-item', item);
        },

        handleDateSelection(props) {
            this.$emit('on-date-selection-end', { startDate: props[0], endDate: props[1] });
            // Don't reset selection to allow for quick addition of new events
        },

        handleDropOnDate(calendarItem, date) {
            this.$emit('on-drop-on-date', {
                item: calendarItem,
                date,
            });
        },
    },
};
</script>

<style lang="scss">
.TaxiCalendar {
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    overflow: hidden;

    &-header {
        background-color: var(--color-blue-dark);
        padding: 16px 20px;
        color: white;

        h2 {
            margin: 0 0 12px 0;
            font-size: 1.4rem;
            font-weight: 500;
        }
    }

    &-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
    }

    &-legends {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;

        .LegendItem {
            display: flex;
            align-items: center;
            font-size: 0.85rem;

            .ColorDot {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                margin-right: 6px;

                &.reservation {
                    background-color: #4a90e2; // Blue
                }

                &.airport {
                    background-color: #9b59b6; // Purple
                }

                &.scheduled {
                    background-color: #2ecc71; // Green
                }

                &.maintenance {
                    background-color: #e74c3c; // Red
                }
            }
        }
    }

    &-actions {
        display: flex;
        gap: 8px;
    }

    &-filters {
        background-color: #f9f9f9;
        padding: 12px 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .FilterGroup {
            display: flex;
            align-items: center;
            gap: 8px;

            label {
                font-size: 0.9rem;
                font-weight: 500;
                color: #555;
            }
        }
    }

    &-info {
        padding: 0 20px 20px;

        .InfoCard {
            background-color: white;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            margin-top: 20px;

            .InfoHeader {
                padding: 12px 16px;
                background-color: #f5f5f5;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #eee;

                h3 {
                    margin: 0;
                    font-size: 1.1rem;
                    font-weight: 500;
                }

                .CloseButton {
                    background: none;
                    border: none;
                    font-size: 1.5rem;
                    line-height: 1;
                    cursor: pointer;
                    padding: 0 5px;
                    color: #666;

                    &:hover {
                        color: #333;
                    }
                }
            }

            .InfoDetails {
                padding: 16px;

                .InfoRow {
                    display: flex;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .InfoLabel {
                        flex: 0 0 100px;
                        font-weight: 500;
                        color: #555;
                    }

                    .InfoValue {
                        flex: 1;
                    }
                }
            }

            .InfoActions {
                padding: 12px 16px;
                background-color: #f9f9f9;
                display: flex;
                justify-content: flex-end;
                gap: 10px;
                border-top: 1px solid #eee;
            }
        }
    }

    // Calendar overrides
    .taxi-calendar-view {
        padding: 10px 20px 20px;

        .cv-header {
            border-radius: 6px;
            background-color: white;
            padding: 8px;
            margin-bottom: 15px;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);

            button {
                background-color: white;
                border-radius: 4px;
                border: 1px solid #e0e0e0;
                padding: 6px 12px;
                font-weight: 500;
                color: #333;
                transition: all 0.2s;

                &:hover {
                    background-color: #f5f5f5;
                    border-color: #ccc;
                }

                &:active {
                    background-color: #eee;
                }
            }

            .periodLabel {
                font-size: 1.1rem;
                font-weight: 500;
            }
        }

        .cv-header-days {
            border: none;

            .cv-header-day {
                background-color: #f5f5f5;
                border-bottom: 1px solid #e0e0e0;
                padding: 8px 0;
                font-weight: 500;
                color: #555;
            }
        }

        .cv-day {
            border-color: #eee;
            transition: background-color 0.2s;

            &:hover {
                background-color: rgba(0, 0, 0, 0.02);
            }

            &.today {
                background-color: rgba(74, 144, 226, 0.1);

                .cv-day-number {
                    background-color: var(--color-blue-dark);
                    color: white;
                    font-weight: 500;
                    width: 24px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                }
            }

            &.outsideOfMonth {
                background-color: #fafafa;

                .cv-day-number {
                    opacity: 0.4;
                }
            }
        }

        .cv-item {
            border-radius: 4px;
            padding: 4px 8px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            border: none;
            transition: transform 0.2s, box-shadow 0.2s;

            &:hover {
                transform: translateY(-1px);
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
            }

            // Event categories
            &.reservation {
                background-color: #4a90e2;
                border-left: 3px solid darken(#4a90e2, 15%);
            }

            &.airport {
                background-color: #9b59b6;
                border-left: 3px solid darken(#9b59b6, 15%);
            }

            &.scheduled {
                background-color: #2ecc71;
                border-left: 3px solid darken(#2ecc71, 15%);
            }

            &.maintenance {
                background-color: #e74c3c;
                border-left: 3px solid darken(#e74c3c, 15%);
            }
        }

        // Selection styles
        .cv-day.selected {
            background-color: rgba(74, 144, 226, 0.15);
        }
    }

    // Responsive adjustments
    @media (max-width: breakpoint(tabletPortrait)) {
        &-header {
            padding: 12px 15px;
        }

        &-controls {
            flex-direction: column;
            align-items: flex-start;
        }

        &-legends {
            margin-bottom: 10px;
            justify-content: flex-start;
            gap: 10px;

            .LegendItem {
                font-size: 0.8rem;
            }
        }

        &-filters {
            flex-direction: column;
            gap: 12px;
            padding: 12px 15px;

            .FilterGroup {
                width: 100%;

                .Dropdown {
                    flex: 1;
                }
            }
        }

        .taxi-calendar-view {
            padding: 5px 10px 15px;

            .cv-header {
                padding: 6px;

                button {
                    padding: 4px 8px;
                    font-size: 0.9rem;
                }

                .periodLabel {
                    font-size: 1rem;
                }
            }
        }

        &-info {
            padding: 0 10px 15px;

            .InfoCard {
                .InfoDetails {
                    .InfoRow {
                        flex-direction: column;
                        margin-bottom: 15px;

                        .InfoLabel {
                            margin-bottom: 4px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: breakpoint(mobile)) {
        &-legends {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        &-actions {
            width: 100%;

            button {
                flex: 1;
            }
        }
    }
}
</style>
