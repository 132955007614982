var render = function () {
  var _vm$driver, _vm$driver2, _vm$driver2$shiftSums;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', {
    staticClass: "OverviewCard DriverCard"
  }, [_c('div', {
    staticClass: "metric-content"
  }, [_c('div', {
    staticClass: "metric-icon"
  }, [_c('i', {
    staticClass: "ri-user-line"
  })]), _c('div', {
    staticClass: "metric-details"
  }, [_c('h3', [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "metric-value"
  }, [_vm._v(" " + _vm._s(((_vm$driver = _vm.driver) === null || _vm$driver === void 0 ? void 0 : _vm$driver.name) || 'N/A') + " ")]), _c('div', {
    staticClass: " metric-change positive"
  }, [_vm._v(" " + _vm._s(_vm.priceToEuroString((_vm$driver2 = _vm.driver) === null || _vm$driver2 === void 0 ? void 0 : (_vm$driver2$shiftSums = _vm$driver2.shiftSums) === null || _vm$driver2$shiftSums === void 0 ? void 0 : _vm$driver2$shiftSums.income)) + " ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }