import axios from 'axios';
import { formatISO, format } from 'date-fns';
import { de } from 'date-fns/locale';
import { priceToEuroString } from '@/lib/helper';

export default {
    /**
     * Fetch dashboard data for a specific month
     * @param {Object} params - The parameters for fetching data
     * @param {number|string} params.year - The year to fetch data for
     * @param {number|string} params.month - The month to fetch data for
     * @returns {Promise} - Promise resolving to the API response
     */
    async fetchByMonth({ year, month }) {
        try {
            const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/dashboard/${year}/${month}`;
            return await axios.get(url, { withCredentials: true });
        } catch (error) {
            console.error('Error fetching month data:', error);
            throw error;
        }
    },

    /**
     * Fetch dashboard data for a date range
     * @param {Object} params - The parameters for fetching data
     * @param {Date} params.from - Start date
     * @param {Date} params.to - End date
     * @returns {Promise} - Promise resolving to the API response
     */
    async fetchByDateRange({ from, to }) {
        try {
            const rangeStartAt = formatISO(from);
            const rangeEndAt = formatISO(to);
            const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/dashboard`;

            return await axios.post(url, { rangeStartAt, rangeEndAt }, { withCredentials: true });
        } catch (error) {
            console.error('Error fetching date range data:', error);
            throw error;
        }
    },

    /**
     * Process and format raw dashboard data
     * @param {Object} response - The raw API response
     * @returns {Object} - Formatted dashboard data
     */
    processApiResponse(response) {
        // Format daily income data for charts
        const chartData = response.dailyIncomes.map(item => ({
            rawDate: item.date,
            date: format(new Date(item.date), 'dd.MM', { locale: de }),
            value: item.income,
            formatter: priceToEuroString,
            formattedValue: priceToEuroString(item.income),
            formattedDate: format(new Date(item.date), 'dd MMM yyyy', { locale: de }),
        }));

        // Format license numbers data
        const formattedLicenseNumbers = this.formatLicenseNumbers(response.licenseNumbers);

        // Format drivers data
        const formattedDrivers = this.formatDrivers(response.drivers);

        return {
            chartData,
            licenseNumbers: formattedLicenseNumbers,
            drivers: formattedDrivers,
            news: response.news,
            widgets: response.widgets,
            reminders: response.reminders,
            cars: response.cars,
        };
    },

    /**
     * Format license numbers data for display
     * @param {Array} licenseNumbers - Raw license numbers data
     * @returns {Array} - Formatted license numbers with row data
     */
    formatLicenseNumbers(licenseNumbers) {
        return licenseNumbers.map(car => ({
            ...car,
            row: [
                {
                    name: 'Fahrzeug',
                    value: car.licenseNumber,
                    rawValue: car.licenseNumber,
                },
                {
                    name: 'Einnahmen',
                    value: priceToEuroString(car.shiftSums.income),
                    rawValue: car.shiftSums.income,
                },
                {
                    name: 'EURO/KM',
                    rawValue: car.shiftSums.totalDistance
                        ? (car.shiftSums.income * 10) / car.shiftSums.totalDistance || 0
                        : 0,
                    value: car.shiftSums.totalDistance
                        ? '≈' +
                          ((car.shiftSums.income * 10) / car.shiftSums.totalDistance || 0).toFixed(
                              2,
                          )
                        : '0',
                },
            ],
        }));
    },

    /**
     * Format drivers data for display
     * @param {Array} drivers - Raw drivers data
     * @returns {Array} - Formatted drivers with row data
     */
    formatDrivers(drivers) {
        return drivers.map(driver => ({
            ...driver,
            isSelected: false,
            row: [
                {
                    name: 'Fahrer',
                    value: driver.name,
                    rawValue: driver.name,
                },
                {
                    name: 'Einnahmen',
                    value: priceToEuroString(driver.shiftSums.income),
                    rawValue: driver.shiftSums.income,
                },
                {
                    name: 'EURO/KM',
                    value: driver.shiftSums.totalDistance
                        ? '≈' +
                          (
                              (driver.shiftSums.income * 10) / driver.shiftSums.totalDistance || 0
                          ).toFixed(2)
                        : '0',
                    rawValue: driver.shiftSums.totalDistance
                        ? (driver.shiftSums.income * 10) / driver.shiftSums.totalDistance
                        : 0,
                },
            ],
        }));
    },

    /**
     * Save the target income goal
     * @param {Object} params - The parameters for saving the goal
     * @param {string} params.uuid - Widget UUID
     * @param {number} params.targetIncome - Target income value
     * @returns {Promise} - Promise resolving to the API response
     */
    async saveTargetIncome({ uuid, targetIncome }) {
        try {
            const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/widgets/${uuid}`;
            return await axios.patch(
                url,
                {
                    name: 'Erreichter Umsatz',
                    type: 'targetIncome',
                    state: {
                        targetIncome: Number(targetIncome),
                    },
                },
                { withCredentials: true },
            );
        } catch (error) {
            console.error('Error saving target income:', error);
            throw error;
        }
    },

    /**
     * Confirm a news item
     * @param {string} newsUuid - The UUID of the news item
     * @returns {Promise} - Promise resolving to the API response
     */
    async confirmNews(newsUuid) {
        try {
            const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/news/${newsUuid}`;
            return await axios.post(url, {}, { withCredentials: true });
        } catch (error) {
            console.error('Error confirming news:', error);
            throw error;
        }
    },

    /**
     * Mute a reminder
     * @param {Object} params - The parameters for muting the reminder
     * @param {string} params.reminderUuid - The UUID of the reminder
     * @param {Date} params.date - The date until which to mute the reminder
     * @returns {Promise} - Promise resolving to the API response
     */
    async muteReminder({ reminderUuid, date }) {
        try {
            const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/reminders/${reminderUuid}`;
            return await axios.patch(
                url,
                { dateTime: date.toISOString() },
                { withCredentials: true },
            );
        } catch (error) {
            console.error('Error muting reminder:', error);
            throw error;
        }
    },
};
