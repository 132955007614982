var render = function () {
  var _vm$news$, _vm$news$2, _vm$news$3, _vm$news$4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Overview",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('LoadingOverlay', {
    attrs: {
      "show": _vm.showLoader,
      "message": "Laden der Daten..."
    }
  }), _c('div', {
    staticClass: "Overview-header"
  }, [_c('div', {
    staticClass: "Overview-dateFilter"
  }, [_c('Datepicker', {
    attrs: {
      "monthPicker": "",
      "startDate": _vm.startDate,
      "end-date": _vm.endDate,
      "has-quick-actions": true
    },
    on: {
      "onChange": _vm.handleDateChange
    }
  })], 1)]), _vm.aggregatedMonthlyData.length ? _c('ChartRevenue', {
    attrs: {
      "chartData": _vm.aggregatedMonthlyData,
      "yearlyIncome": _vm.yearlyIncome,
      "prevYearIncome": _vm.prevYearIncome,
      "startDate": _vm.startDate,
      "endDate": _vm.endDate
    },
    on: {
      "onLeave": function onLeave($event) {
        _vm.activeRow = null;
      }
    }
  }) : _vm.data.length ? _c('ChartRevenue', {
    attrs: {
      "chartData": _vm.data,
      "yearlyIncome": _vm.monthlyIncome,
      "prevYearIncome": _vm.lastMonthIncome,
      "startDate": _vm.startDate,
      "endDate": _vm.endDate,
      "comparisonMonthly": true
    },
    on: {
      "onLeave": function onLeave($event) {
        _vm.activeRow = null;
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "OverviewCards"
  }, [_vm.widgets.find(function (widget) {
    return widget.type === 'targetIncome';
  }) ? _c('RevenueTarget', {
    attrs: {
      "monthlyIncome": _vm.monthlyIncome,
      "targetIncome": _vm.targetIncome
    },
    on: {
      "onSettingsClick": _vm.handleOnSettingsClick
    }
  }) : _vm._e(), _c('DriverPerformance', {
    attrs: {
      "driver": _vm.highestIncomeDriver,
      "title": "Bester Fahrer"
    }
  }), _c('VehiclePerformance', {
    attrs: {
      "vehicle": _vm.highestIncomeCar,
      "title": "Bestes Fahrzeug"
    }
  }), _vm.dueReminders.length ? _c('ReminderStatus', {
    attrs: {
      "reminders": _vm.dueReminders
    }
  }) : _vm._e(), _vm.incorrectLogin.length ? _c('LoginIssues', {
    attrs: {
      "logins": _vm.incorrectLogin
    },
    on: {
      "onEditClick": _vm.handleIncorrectEditClick
    }
  }) : _vm._e()], 1), !_vm.hasNoData ? _c('div', {
    staticClass: "Overview-grid"
  }, [_c('DriversTable', {
    attrs: {
      "drivers": _vm.drivers.filter(function (d) {
        return d.shiftSums.income > 0;
      }),
      "has-search": false
    },
    on: {
      "onDriverClick": _vm.handleDriverClick,
      "onRowHover": function onRowHover($event) {
        return _vm.$emit('onRowHover', $event);
      },
      "onRowLeave": function onRowLeave($event) {
        return _vm.$emit('onRowLeave', $event);
      }
    }
  }), _c('VehiclesTable', {
    attrs: {
      "vehicles": _vm.licenseNumbers.filter(function (d) {
        return d.shiftSums.income > 0;
      }),
      "has-search": false
    },
    on: {
      "onVehicleClick": _vm.handleCarClick,
      "onRowHover": function onRowHover($event) {
        return _vm.$emit('onRowHover', $event);
      },
      "onRowLeave": function onRowLeave($event) {
        return _vm.$emit('onRowLeave', $event);
      }
    }
  }), _c('PerformanceDetails', {
    attrs: {
      "item": _vm.selectedItem,
      "name": _vm.selectedDriver.name || _vm.selectedCar.licenseNumber
    }
  }), _vm.hasReminderFeature && _vm.groupedReminders.length ? _c('RemindersTable', {
    attrs: {
      "reminders": _vm.groupedReminders,
      "has-search": false
    },
    on: {
      "onReminderClick": _vm.handleReminderClick,
      "onReminderMute": _vm.handleReminderMute
    }
  }) : _vm._e(), _c('AverageMetrics', {
    attrs: {
      "drivers": _vm.drivers,
      "vehicles": _vm.licenseNumbers
    }
  }), _c('InactiveVehicles', {
    attrs: {
      "title": "Kein Signal über 2+ Tage",
      "vehicles": _vm.carsWithNoPingInLast3Days,
      "has-search": false
    },
    on: {
      "onRowHover": function onRowHover($event) {
        return _vm.$emit('onRowHover', $event);
      },
      "onRowLeave": function onRowLeave($event) {
        return _vm.$emit('onRowLeave', $event);
      }
    }
  }), _c('InactiveVehicles', {
    attrs: {
      "title": "Keine Daten über 2+ Tage",
      "vehicles": _vm.carsWithNoDataInLast3Days,
      "has-search": false
    },
    on: {
      "onRowHover": function onRowHover($event) {
        return _vm.$emit('onRowHover', $event);
      },
      "onRowLeave": function onRowLeave($event) {
        return _vm.$emit('onRowLeave', $event);
      }
    }
  }), _c('SupportInfo')], 1) : _c('EmptyState'), _c('RevenueGoalModal', {
    attrs: {
      "show": _vm.revenueGoalModal,
      "targetIncome": _vm.targetIncome,
      "isLoading": _vm.isSavingRevenueGoal
    },
    on: {
      "onClose": _vm.handleModalClose,
      "onSave": _vm.handleSaveRevenueGoal,
      "onChange": _vm.handleTargetIncomeChange
    }
  }), _c('NewsModal', {
    attrs: {
      "show": _vm.news.length > 0,
      "title": (_vm$news$ = _vm.news[0]) === null || _vm$news$ === void 0 ? void 0 : _vm$news$.title,
      "uuid": (_vm$news$2 = _vm.news[0]) === null || _vm$news$2 === void 0 ? void 0 : _vm$news$2.uuid,
      "needsConfirmation": !!((_vm$news$3 = _vm.news[0]) !== null && _vm$news$3 !== void 0 && _vm$news$3.needsConfirmation),
      "message": (_vm$news$4 = _vm.news[0]) === null || _vm$news$4 === void 0 ? void 0 : _vm$news$4.text,
      "onHasConfirmed": _vm.handleNewsModelConfirm,
      "isLoading": _vm.isNewsModalLoading
    },
    on: {
      "onClose": _vm.handleNewsModalClose
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }