var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseDashboardTable', {
    staticClass: "DriverTable compact-mode",
    attrs: {
      "columns": _vm.tableColumns,
      "items": _vm.tableItems,
      "sort": _vm.localSortState,
      "selectedFn": function selectedFn(item) {
        return item.isSelected;
      },
      "isClickable": true,
      "hasSearch": false,
      "showItemCounter": false,
      "emptyMessage": "Keine Daten"
    },
    on: {
      "sort-changed": _vm.handleSortChange,
      "row-click": _vm.handleDriverClick,
      "row-hover": _vm.handleRowHover,
      "row-leave": _vm.handleRowLeave
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h3', [_vm._v("Fahrer")])];
      },
      proxy: true
    }, {
      key: "column-income",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.formatIncome(item.income)) + " ")];
      }
    }, {
      key: "column-euroPerKm",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.formatEuroPerKm(item.euroPerKm)) + " ")];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }