<template>
    <Card class="OverviewCard StatusCard" variant="warning">
        <div class="metric-content">
            <div class="metric-icon" :class="reminders.length > 0 ? 'negative' : 'positive'">
                <i class="ri-calendar-event-fill"></i>
            </div>
            <div class="metric-details">
                <h3>Fällige Termine</h3>
                <div class="metric-value">{{ reminders.length }}</div>
                <div
                    class="metric-change"
                    :class="reminders.length > 0 ? 'negative' : 'positive'"
                ></div>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from '@/components/Card';
import Button from '@/components/widgets/Button';

export default {
    name: 'ReminderStatus',
    components: {
        Card,
        Button,
    },
    props: {
        reminders: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss">
@import './OverviewCard.scss';

.StatusCard {
    &.warn {
        border-left: 3px solid var(--color-orange);
    }
}
</style>
