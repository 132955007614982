<template>
    <Card class="OverviewCard VehicleCard">
        <div class="metric-content">
            <div class="metric-icon">
                <i class="ri-taxi-line"></i>
            </div>
            <div class="metric-details">
                <h3>{{ title }}</h3>
                <div class="metric-value">{{ vehicle?.licenseNumber || 'N/A' }}</div>
                <div class="metric-change positive">
                    {{ priceToEuroString(vehicle?.shiftSums?.income) }}
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from '@/components/Card';
import { priceToEuroString, distanceToString } from '@/lib/helper';

export default {
    name: 'VehiclePerformance',
    components: { Card },
    props: {
        vehicle: {
            type: Object,
            default: () => ({}),
        },
        title: {
            type: String,
            default: 'Fahrzeug',
        },
    },
    methods: {
        priceToEuroString,
        distanceToString,
    },
};
</script>

<style lang="scss">
@import './OverviewCard.scss';

.VehicleCard {
    .metric-icon {
        background-color: rgba(var(--color-yellow-rgb), 0.1);
        color: var(--color-yellow);
    }
}
</style>
