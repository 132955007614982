<template>
    <div class="InactiveVehiclesCard">
        <BaseDashboardTable
            v-if="formattedVehicles.length > 0"
            :columns="columns"
            :items="formattedVehicles"
            :sort="sortState"
            :isClickable="false"
            :empty-message="'Keine inaktiven Fahrzeuge'"
            class="inactive-vehicles-table"
            :has-search="false"
            :showItemCounter="false"
            @sort-changed="handleSortChanged"
            @row-hover="args => $emit('onRowHover', args)"
            @row-leave="args => $emit('onRowLeave', args)"
        >
            <template #title>
                <h3 class="">{{ title }}</h3>
            </template>
        </BaseDashboardTable>

        <div v-else class="InactiveVehiclesCard-empty">
            <i class="ri-check-circle-line"></i>
            <p>Alle Fahrzeuge aktiv</p>
        </div>
    </div>
</template>

<script>
import Card from '@/components/Card';
import BaseDashboardTable from '@/components/BaseDashboardTable';

export default {
    name: 'InactiveVehicles',
    components: {
        Card,
        BaseDashboardTable,
    },
    props: {
        vehicles: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            sortState: {
                column: 'licenseNumber',
                ascending: true,
            },
            columns: [
                {
                    key: 'licenseNumber',
                    label: 'Kennzeichen',
                    sortable: true,
                    formatter: value => value,
                },
                {
                    key: 'status',
                    label: 'Status',
                    formatter: value => value,
                },
            ],
        };
    },

    computed: {
        formattedVehicles() {
            if (!this.vehicles || this.vehicles.length === 0) {
                return [];
            }

            let formatted = this.vehicles.map(vehicle => {
                return {
                    licenseNumber: vehicle.licenseNumber || '',
                    status: 'Kein Signal 2+ Tage',
                    uuid: vehicle.uuid,
                    isVisible: vehicle.isVisible,
                    originalVehicle: vehicle,
                };
            });

            // Apply sorting
            if (this.sortState.column) {
                formatted.sort((a, b) => {
                    let aValue = a[this.sortState.column];
                    let bValue = b[this.sortState.column];

                    // Handle null/undefined values
                    if (!aValue) aValue = '';
                    if (!bValue) bValue = '';

                    // Convert to lowercase for string comparison
                    if (typeof aValue === 'string') aValue = aValue.toLowerCase();
                    if (typeof bValue === 'string') bValue = bValue.toLowerCase();

                    if (aValue < bValue) return this.sortState.ascending ? -1 : 1;
                    if (aValue > bValue) return this.sortState.ascending ? 1 : -1;
                    return 0;
                });
            }

            return formatted;
        },
    },
    methods: {
        handleSortChanged(sort) {
            this.sortState = {
                column: sort.column,
                ascending: sort.ascending,
            };
        },
    },
};
</script>

<style lang="scss">
.InactiveVehiclesCard {
    &-title {
        font-weight: 600;
        font-size: 1rem;
        margin: 0;
        padding-bottom: 12px;
        color: #e74c3c;
        border-bottom: none; // Remove border as BaseDashboardTable has its own header style
    }

    &-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px 20px;
        color: #27ae60;

        i {
            font-size: 2rem;
            margin-bottom: 10px;
        }

        p {
            font-size: 1rem;
            text-align: center;
        }
    }

    .inactive-vehicles-table {
        .table-content {
            table {
                tbody {
                    tr {
                        border-left: 3px solid #e74c3c !important;

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.04) !important;
                        }
                    }
                }
            }
        }
    }
}
</style>
