var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    attrs: {
      "title": "Umsatzziel ändern",
      "show": _vm.show,
      "action": {
        text: 'Speichern',
        color: 'green',
        callback: _vm.handleSave
      },
      "cancel": {
        text: 'Abbrechen'
      },
      "isLoading": _vm.isLoading
    },
    on: {
      "onModalClose": function onModalClose($event) {
        return _vm.$emit('onClose');
      }
    }
  }, [_c('div', {
    staticClass: "RevenueGoalModal"
  }, [_c('p', {
    staticClass: "RevenueGoalModal-description"
  }, [_vm._v(" Bitte geben Sie den neuen Umsatz ein, den Sie erreichen möchten (in Euro) pro Monat. ")]), _c('div', {
    staticClass: "RevenueGoalModal-input"
  }, [_c('label', {
    attrs: {
      "for": "targetIncome"
    }
  }, [_vm._v("Umsatzziel (in Euro)")]), _c('Input', {
    attrs: {
      "id": "targetIncome",
      "type": "number",
      "keyName": "targetIncome",
      "value": _vm.targetIncome
    },
    on: {
      "onKeyPress": _vm.handleChange
    }
  })], 1), _c('div', {
    staticClass: "RevenueGoalModal-preview"
  }, [_c('div', {
    staticClass: "RevenueGoalModal-preview-label"
  }, [_vm._v("Vorschau:")]), _c('div', {
    staticClass: "RevenueGoalModal-preview-amount"
  }, [_vm._v(" " + _vm._s(_vm.formattedTargetIncome) + " ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }