<template>
    <div class="Overview" :class="{ 'is-loading': isFetchingData }">
        <!-- Loading Component -->
        <LoadingOverlay :show="showLoader" message="Laden der Daten..." />

        <!-- Header with Date Filter -->
        <div class="Overview-header">
            <div class="Overview-dateFilter">
                <Datepicker
                    monthPicker
                    @onChange="handleDateChange"
                    :startDate="startDate"
                    :end-date="endDate"
                    :has-quick-actions="true"
                />
            </div>
        </div>

        <!-- Revenue Chart -->
        <ChartRevenue
            v-if="aggregatedMonthlyData.length"
            :chartData="aggregatedMonthlyData"
            :yearlyIncome="yearlyIncome"
            :prevYearIncome="prevYearIncome"
            :startDate="startDate"
            :endDate="endDate"
            @onLeave="activeRow = null"
        />

        <ChartRevenue
            v-else-if="data.length"
            :chartData="data"
            :yearlyIncome="monthlyIncome"
            :prevYearIncome="lastMonthIncome"
            :startDate="startDate"
            :endDate="endDate"
            :comparisonMonthly="true"
            @onLeave="activeRow = null"
        />

        <!-- Summary Cards -->
        <div class="OverviewCards">
            <RevenueTarget
                v-if="widgets.find(widget => widget.type === 'targetIncome')"
                :monthlyIncome="monthlyIncome"
                :targetIncome="targetIncome"
                @onSettingsClick="handleOnSettingsClick"
            />

            <DriverPerformance :driver="highestIncomeDriver" title="Bester Fahrer" />

            <VehiclePerformance :vehicle="highestIncomeCar" title="Bestes Fahrzeug" />

            <ReminderStatus v-if="dueReminders.length" :reminders="dueReminders" />

            <LoginIssues
                v-if="incorrectLogin.length"
                :logins="incorrectLogin"
                @onEditClick="handleIncorrectEditClick"
            />
        </div>

        <!-- Main Content Grid -->
        <div class="Overview-grid" v-if="!hasNoData">
            <!-- Driver Performance Table -->
            <DriversTable
                :drivers="drivers.filter(d => d.shiftSums.income > 0)"
                @onDriverClick="handleDriverClick"
                :has-search="false"
                @onRowHover="$emit('onRowHover', $event)"
                @onRowLeave="$emit('onRowLeave', $event)"
            />

            <!-- Vehicles Table -->
            <VehiclesTable
                :vehicles="licenseNumbers.filter(d => d.shiftSums.income > 0)"
                @onVehicleClick="handleCarClick"
                :has-search="false"
                @onRowHover="$emit('onRowHover', $event)"
                @onRowLeave="$emit('onRowLeave', $event)"
            />

            <!-- Selected Item Performance -->
            <PerformanceDetails
                :item="selectedItem"
                :name="selectedDriver.name || selectedCar.licenseNumber"
            />

            <!-- Reminders Section -->
            <RemindersTable
                v-if="hasReminderFeature && groupedReminders.length"
                :reminders="groupedReminders"
                :has-search="false"
                @onReminderClick="handleReminderClick"
                @onReminderMute="handleReminderMute"
            />
            <!-- Average Performance Metrics -->
            <AverageMetrics :drivers="drivers" :vehicles="licenseNumbers" />

            <!-- Vehicles with No Signal -->
            <InactiveVehicles
                title="Kein Signal über 2+ Tage"
                :vehicles="carsWithNoPingInLast3Days"
                :has-search="false"
                @onRowHover="$emit('onRowHover', $event)"
                @onRowLeave="$emit('onRowLeave', $event)"
            />

            <!-- Vehicles with No Data -->
            <InactiveVehicles
                title="Keine Daten über 2+ Tage"
                :vehicles="carsWithNoDataInLast3Days"
                :has-search="false"
                @onRowHover="$emit('onRowHover', $event)"
                @onRowLeave="$emit('onRowLeave', $event)"
            />

            <!-- Support Info Card -->
            <SupportInfo />
        </div>

        <!-- Empty State for No Data -->
        <EmptyState v-else />

        <!-- Revenue Goal Modal -->
        <RevenueGoalModal
            :show="revenueGoalModal"
            :targetIncome="targetIncome"
            :isLoading="isSavingRevenueGoal"
            @onClose="handleModalClose"
            @onSave="handleSaveRevenueGoal"
            @onChange="handleTargetIncomeChange"
        />

        <!-- News Modal -->
        <NewsModal
            :show="news.length > 0"
            :title="news[0]?.title"
            :uuid="news[0]?.uuid"
            :needsConfirmation="!!news[0]?.needsConfirmation"
            :message="news[0]?.text"
            :onHasConfirmed="handleNewsModelConfirm"
            :isLoading="isNewsModalLoading"
            @onClose="handleNewsModalClose"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format, startOfMonth, subMonths, addMonths, isFuture, isPast } from 'date-fns';
import { de } from 'date-fns/locale';
import DashboardService from '@/services/DashboardService';

// Import individual components
import LoadingOverlay from './components/LoadingOverlay.vue';
import RevenueTarget from './components/CardRevenueTarget.vue';
import DriverPerformance from './components/CardDriverPerformance.vue';
import VehiclePerformance from './components/CardVehiclePerformance.vue';
import ReminderStatus from './components/CardReminderStatus.vue';
import LoginIssues from './components/CardLoginIssues.vue';
import ChartRevenue from './components/ChartRevenue.vue';
import DriversTable from './components/TableDrivers.vue';
import RemindersTable from './components/TableReminders.vue';
import VehiclesTable from './components/TableVehicles.vue';
import PerformanceDetails from './components/PerformanceDetails.vue';
import AverageMetrics from './components/TableAverageMetrics.vue';
import InactiveVehicles from './components/TableInactiveVehicles.vue';
import SupportInfo from './components/SupportInfo.vue';
import EmptyState from '@/components/EmptyState.vue';
import RevenueGoalModal from './components/ModalRevenueGoal.vue';
import NewsModal from '@/components/NewsModal';
import Datepicker from '@/components/widgets/Datepicker';
import EventsCalendar from './components/EventsCalendarCard.vue';
import TaxiEventModal from './components/ModalTaxiEvent.vue';

import {
    priceToEuroString,
    distanceToString,
    percentageToString,
    timeToString,
} from '@/lib/helper';
import { topicToLabel } from '../Cars/Car/components/services';

export default {
    name: 'Dashboard',
    components: {
        LoadingOverlay,
        RevenueTarget,
        DriverPerformance,
        VehiclePerformance,
        ReminderStatus,
        LoginIssues,
        ChartRevenue,
        DriversTable,
        RemindersTable,
        VehiclesTable,
        PerformanceDetails,
        AverageMetrics,
        InactiveVehicles,
        SupportInfo,
        EmptyState,
        RevenueGoalModal,
        NewsModal,
        Datepicker,
        EventsCalendar,
        TaxiEventModal,
    },
    data() {
        return {
            // API-related data
            isFetchingData: false,
            isNewsModalLoading: false,
            isSavingRevenueGoal: false,
            showLoader: false,

            // Date filters
            startDate: startOfMonth(new Date()),
            endDate: new Date(),

            // Revenue data
            monthlyIncome: 0,
            yearlyIncome: 0,
            prevYearIncome: 0,
            targetIncome: 7000000,

            // Chart data
            data: [],

            // Entity collections
            drivers: [],
            cars: [],
            licenseNumbers: [],
            reminders: [],
            news: [],
            widgets: [],

            // Selected data
            selectedItem: {},
            activeRow: null,
            revenueGoalModal: false,

            // Last month data
            lastMonthIncome: 0,
        };
    },

    computed: {
        ...mapGetters(['contactPerson', 'modules']),

        // Check if there is no data to display
        hasNoData() {
            const nonSystemDrivers = this.drivers.filter(driver => !driver.isSystemDriver);
            return this.licenseNumbers.length === 0 && nonSystemDrivers.length === 0;
        },

        // Check if reminder feature is available
        hasReminderFeature() {
            return this.modules.includes('reminder');
        },

        // Aggregate monthly data for charts
        aggregatedMonthlyData() {
            if (!this.data.length) return [];

            // Group income by month
            const monthlyAggregation = this.data.reduce((acc, { rawDate, value }) => {
                const monthKey = format(new Date(rawDate), 'yyyy-MM');
                if (!acc[monthKey]) {
                    acc[monthKey] = 0;
                }
                acc[monthKey] += value;
                return acc;
            }, {});

            // Convert to chart format
            const chartData = Object.entries(monthlyAggregation).map(([month, total]) => {
                const date = new Date(`${month}-01`);
                return {
                    date: format(date, 'M yy', { locale: de }),
                    value: total,
                    formattedValue: priceToEuroString(total),
                    rawDate: date,
                    formatter: priceToEuroString,
                    formattedDate: format(date, 'MMM yy', { locale: de }),
                };
            });

            return chartData.length > 1 ? chartData : [];
        },

        // Selected car from data
        selectedCar() {
            return this.licenseNumbers.find(car => car.isSelected) || {};
        },

        // Selected driver from data
        selectedDriver() {
            return this.drivers.find(driver => driver.isSelected) || {};
        },

        // Find cars with no data in last 3 days
        carsWithNoDataInLast3Days() {
            const now = new Date();
            return this.licenseNumbers
                .filter(car => {
                    if (!car.lastDataAt) return false;
                    const lastData = new Date(car.lastDataAt);
                    const diff = now.getTime() - lastData.getTime();
                    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
                    return diffDays > 3;
                })
                .map(car => {
                    const lastDataDate = new Date(car.lastDataAt);
                    const daysSinceLastSignal = Math.ceil(
                        (now - lastDataDate) / (1000 * 60 * 60 * 24),
                    );

                    return {
                        ...car,
                        daysSinceLastSignal,
                        row: [
                            {
                                name: 'Fahrzeug',
                                value: car.licenseNumber,
                            },
                            {
                                name: 'Letztes Signal',
                                value: format(new Date(car.lastDataAt), 'dd.MM.yy HH:mm', {
                                    locale: de,
                                }),
                            },
                        ],
                    };
                })
                .sort((a, b) => b.daysSinceLastSignal - a.daysSinceLastSignal);
        },

        // Find cars with no ping in last 3 days
        carsWithNoPingInLast3Days() {
            return this.licenseNumbers
                .filter(car => {
                    if (!car.lastPingAt) return false;
                    const lastPing = new Date(car.lastPingAt);
                    const now = new Date();
                    const diff = now.getTime() - lastPing.getTime();
                    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
                    return diffDays > 3;
                })
                .map(car => ({
                    ...car,
                    row: [
                        {
                            name: 'Fahrzeug',
                            value: car.licenseNumber,
                        },
                        {
                            name: 'Letztes Signal',
                            value: format(new Date(car.lastPingAt), 'dd.MM.yy HH:mm', {
                                locale: de,
                            }),
                        },
                    ],
                }))
                .sort((a, b) => {
                    const aDate = new Date(a.lastPingAt);
                    const bDate = new Date(b.lastPingAt);
                    return aDate - bDate;
                });
        },

        // Find drivers with incorrect login
        incorrectLogin() {
            if (!this.drivers?.length) return [];

            return this.drivers
                .filter(driver => {
                    const drivers = JSON.parse(localStorage.getItem('drivers'));
                    if (!drivers) return false;
                    const currentDriver = drivers.find(d => d.id === driver.driverNumber);
                    return driver.driverNumber == driver.name;
                })
                .map(driver => ({
                    ...driver,
                    row: [{ name: 'Fahrer', value: driver.name }],
                }));
        },

        // Find highest income car
        highestIncomeCar() {
            const car = this.licenseNumbers.reduce(
                (prev, current) =>
                    (prev?.shiftSums?.income || 0) > (current?.shiftSums?.income || 0)
                        ? prev
                        : current,
                {},
            );

            return (car?.shiftSums?.income || 0) > 0 ? car : { ...car, licenseNumber: 'N/A' };
        },

        // Find highest income driver
        highestIncomeDriver() {
            const driver = this.drivers.reduce(
                (prev, current) =>
                    (prev?.shiftSums?.income || 0) > (current?.shiftSums?.income || 0)
                        ? prev
                        : current,
                {},
            );

            return (driver?.shiftSums?.income || 0) > 0 ? driver : { ...driver, name: 'N/A' };
        },

        // Due reminders
        dueReminders() {
            return this.groupedReminders.reduce((acc, group) => {
                acc.push(...group.items.filter(r => r.isDue));
                return acc;
            }, []);
        },

        // Group reminders by entity
        groupedReminders() {
            const entityTranslations = {
                Driver: 'Fahrer',
                Car: 'Fahrzeug',
            };

            // Create a map of reminders grouped by entity
            const remindersByEntity = this.reminders.reduce((acc, reminder) => {
                if (!acc[reminder.entity]) {
                    acc[reminder.entity] = [];
                }
                acc[reminder.entity].push(reminder);
                return acc;
            }, {});

            // Convert the map to array format needed for display
            return Object.keys(remindersByEntity).map(entity => {
                const reminders = remindersByEntity[entity];
                // Sort reminders by deadline
                reminders.sort((a, b) => new Date(a.deadline * 1000) - new Date(b.deadline * 1000));

                // Format each reminder
                const items = this.formatReminders(reminders, entity);

                return {
                    entity: entityTranslations[entity] || entity,
                    items,
                };
            });
        },
    },

    watch: {
        isFetchingData(newValue) {
            if (newValue) {
                // Show loader immediately when fetching starts
                this.showLoader = true;
            } else {
                // Hide loader with animation when fetching ends
                setTimeout(() => {
                    this.showLoader = false;
                }, 300);
            }
        },
    },

    methods: {
        // Format reminders for display
        formatReminders(reminders, entityType) {
            return reminders.map(reminder => {
                const deadline = new Date(reminder.deadline * 1000);
                const type = reminder.type;

                // Format deadline based on type
                const formattedDeadline =
                    type === 'dateTime'
                        ? `Am ${format(deadline, 'dd.MM.yyyy', { locale: de })}`
                        : `In ${this.distanceToString(
                              Math.abs(reminder.currentValue - reminder.deadline),
                              'km',
                          )}`;

                // Check if reminder is due
                const isDue =
                    type === 'dateTime'
                        ? isPast(deadline)
                        : Math.abs(reminder.currentValue - reminder.deadline) <= 0;

                // Find related entity
                const driver = this.drivers.find(d => d.uuid === reminder.entityUuid);
                const car = this.cars.find(c => c.uuid === reminder.entityUuid);
                const licenseNumber = car?.licenseNumber;

                // Build reminder row
                return {
                    index: reminder.entityUuid,
                    uuid: reminder.entityUuid,
                    reminder: reminder, // Pass full reminder object for muting
                    isSelected: false,
                    isDue,
                    row: [
                        {
                            value:
                                topicToLabel[reminder.topic] +
                                ' <br>' +
                                (driver?.name || licenseNumber || car?.vin || ''),
                            name: '',
                        },
                        {
                            value: formattedDeadline,
                            name: '',
                            props: { style: 'align-items: flex-end;' },
                        },
                        {
                            name: '',
                            value: `<span style="padding:1px; font-size:1rem;cursor:pointer;"><i class="ButtonSilence ri-notification-off-line"></i></span>`,
                            props: {
                                dayPicker: true,
                                keyName: 'isVisible',
                                variant: 'inline',
                                size: 'small',
                                style:
                                    'flex:none; height:100%; justify-content:flex-end; align-items:center; text-decoration:none; cursor:pointer;',
                                tooltip: 'Eintrag Stummschalten',
                                startDate: new Date(),
                                endDate: new Date(reminder.deadline * 1000),
                                onDateChange: range => {
                                    this.handleReminderMute({ reminder, range });
                                },
                            },
                            component: 'Datepicker',
                        },
                    ],
                };
            });
        },

        // Helper to format distance
        distanceToString(value, unit = '') {
            const formatted = new Intl.NumberFormat('de-DE').format(Math.round(value));
            return unit ? `${formatted} ${unit}` : formatted;
        },

        // Handle news modal close
        handleNewsModalClose() {
            this.news.shift();
        },

        // Handle news confirmation
        async handleNewsModelConfirm() {
            this.isNewsModalLoading = true;

            try {
                await DashboardService.confirmNews(this.news[0]?.uuid);
                this.$toasted.show('Nachricht wurde bestätigt.', { type: 'success' });
            } catch (error) {
                console.error(error);
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isNewsModalLoading = false;
            }
        },

        // Handle reminder click
        async handleReminderClick(rowItem, e) {
            const { event } = e;
            const target = event.target;

            // Ignore clicks on the mute button
            if (target.classList.contains('ButtonSilence')) {
                return;
            }

            // Navigate to appropriate page based on entity type
            const car = this.cars.find(car => car.uuid === rowItem.uuid);
            const driver = this.drivers.find(driver => driver.uuid === rowItem.uuid);

            if (car) {
                this.$router.push({ path: '/cars/' + car.uuid });
            } else if (driver) {
                this.$router.push({
                    path: '/employees/',
                    query: { uuid: driver.uuid },
                });
            }
        },

        // Handle reminder mute
        async handleReminderMute({ reminder, range }) {
            try {
                await DashboardService.muteReminder({
                    reminderUuid: reminder.uuid,
                    date: range.from,
                });

                this.$toasted.show(
                    `Erinnerung wurde bis zum ${format(range.from, 'dd.MM.yyyy')} stummgeschaltet.`,
                    { type: 'success' },
                );

                await this.handleQuery();
            } catch (error) {
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            }
        },

        // Handle date change from date picker
        handleDateChange(date) {
            this.startDate = new Date(date.from);
            this.endDate = new Date(date.to);
            this.handleQuery();
        },

        // Set active bar selection
        setActiveBar(item) {
            // Update driver selection
            this.drivers = this.drivers.map(driver => ({
                ...driver,
                isSelected: driver.driverNumber === item.driverNumber ? !driver.isSelected : false,
            }));

            // Update car selection
            this.licenseNumbers = this.licenseNumbers.map(car => ({
                ...car,
                isSelected: car.licenseNumber === item.licenseNumber ? !car.isSelected : false,
            }));
        },

        // Handle incorrect login edit click
        handleIncorrectEditClick() {
            this.$router.push({
                name: 'shifts',
                query: { incorrect: true },
            });
        },

        // Handle driver click
        handleDriverClick({ item }) {
            const activeDriver = this.drivers.find(driver => driver.isSelected);
            const selectedDriver = this.drivers.find(
                driver => driver.driverNumber === item.driverNumber,
            );

            if (selectedDriver.driverNumber === activeDriver?.driverNumber) {
                // If clicking on already selected driver, navigate to shifts
                this.$router.push({
                    name: 'shifts',
                    query: {
                        driverNumber: selectedDriver.driverNumber,
                        startDate: startOfMonth(this.startDate),
                    },
                });
            } else {
                // Otherwise, select the driver and show performance
                this.setActiveBar(item);
                this.selectedItem = selectedDriver.shiftSums;
            }
        },

        // Handle car click
        handleCarClick({ item }) {
            const activeCar = this.licenseNumbers.find(car => car.isSelected);
            const selectedCar = this.licenseNumbers.find(
                car => car?.licenseNumber === item.licenseNumber,
            );

            if (selectedCar.licenseNumber === activeCar?.licenseNumber) {
                // If clicking on already selected car, navigate to shifts
                this.$router.push({
                    name: 'shifts',
                    query: {
                        licenseNumber: selectedCar.licenseNumber,
                        startDate: startOfMonth(this.startDate),
                    },
                });
            } else {
                // Otherwise, select the car and show performance
                this.setActiveBar(item);
                this.selectedItem = selectedCar.shiftSums;
            }
        },

        // Handle settings click for revenue goal
        handleOnSettingsClick() {
            this.revenueGoalModal = true;
        },

        // Handle close of revenue goal modal
        handleModalClose() {
            this.revenueGoalModal = false;
        },

        // Handle saving revenue goal
        async handleSaveRevenueGoal() {
            this.isSavingRevenueGoal = true;

            const widget = this.widgets.find(widget => widget.type === 'targetIncome');
            if (!widget) {
                this.isSavingRevenueGoal = false;
                this.revenueGoalModal = false;
                return;
            }

            try {
                await DashboardService.saveTargetIncome({
                    uuid: widget.uuid,
                    targetIncome: this.targetIncome,
                });

                this.$toasted.show('Umsatzziel wurde erfolgreich gespeichert.', {
                    type: 'success',
                });
            } catch (error) {
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isSavingRevenueGoal = false;
                this.revenueGoalModal = false;
            }
        },

        // Handle target income change from input
        handleTargetIncomeChange({ targetIncome }) {
            this.targetIncome = targetIncome;
        },

        // Main query handler to fetch data
        async handleQuery() {
            this.isFetchingData = true;

            try {
                // Calculate difference in months between start and end dates
                const diffInMonths =
                    this.endDate.getFullYear() * 12 +
                    this.endDate.getMonth() -
                    (this.startDate.getFullYear() * 12 + this.startDate.getMonth());

                let aggregatedResponse = null;

                if (diffInMonths > 1) {
                    // For multi-month range, fetch current and previous year data
                    const resultPromise = DashboardService.fetchByDateRange({
                        from: this.startDate,
                        to: this.endDate,
                    });

                    const previousStartDate = subMonths(this.startDate, 12);
                    const previousEndDate = subMonths(this.endDate, 12);
                    const prevResultPromise = DashboardService.fetchByDateRange({
                        from: previousStartDate,
                        to: previousEndDate,
                    });

                    const [result, prevResult] = await Promise.all([
                        resultPromise,
                        prevResultPromise,
                    ]);

                    aggregatedResponse = result.data;

                    // Calculate yearly income values
                    this.yearlyIncome = result.data.dailyIncomes.reduce(
                        (sum, { income }) => sum + income,
                        0,
                    );
                    this.prevYearIncome = prevResult.data.dailyIncomes.reduce(
                        (sum, { income }) => sum + income,
                        0,
                    );
                } else {
                    // For single month, fetch current and previous month
                    const year = format(this.startDate, 'yyyy');
                    const month = format(this.startDate, 'MM');
                    const resultPromise = DashboardService.fetchByMonth({ year, month });

                    const comparisonYear = format(subMonths(this.startDate, 1), 'yyyy');
                    const comparisonMonth = format(subMonths(this.startDate, 1), 'MM');
                    const comparisonResultPromise = DashboardService.fetchByMonth({
                        year: comparisonYear,
                        month: comparisonMonth,
                    });

                    const [result, comparisonResult] = await Promise.all([
                        resultPromise,
                        comparisonResultPromise,
                    ]);

                    aggregatedResponse = result.data;

                    // Calculate monthly income values
                    this.monthlyIncome = aggregatedResponse.dailyIncomes.reduce(
                        (sum, item) => sum + item.income,
                        0,
                    );

                    // Filter comparison data to exclude future dates
                    const filteredLastMonthIncomes = comparisonResult.data.dailyIncomes.filter(
                        item => {
                            const itemDate = new Date(item.date);
                            const isFutureDate = isFuture(addMonths(itemDate, 1));
                            return !isFutureDate;
                        },
                    );

                    // Calculate last month income
                    this.lastMonthIncome = filteredLastMonthIncomes.reduce(
                        (sum, item) => sum + item.income,
                        0,
                    );
                }

                // Get processed data from service
                const processedData = DashboardService.processApiResponse(aggregatedResponse);

                // Update component data with processed values
                this.data = processedData.chartData;
                this.news = processedData.news;
                this.widgets = processedData.widgets;
                this.reminders = processedData.reminders;
                this.cars = processedData.cars;
                this.licenseNumbers = processedData.licenseNumbers;
                this.drivers = processedData.drivers;

                // Set target income from widgets
                const targetIncomeWidget = this.widgets.find(w => w.type === 'targetIncome');
                if (targetIncomeWidget) {
                    this.targetIncome = targetIncomeWidget.state.targetIncome;
                }

                // Set initial selected item (highest income driver)
                if (this.highestIncomeDriver && this.highestIncomeDriver.shiftSums) {
                    this.selectedItem = this.highestIncomeDriver.shiftSums;

                    // Mark the highest income driver as selected in the drivers array
                    this.drivers = this.drivers.map(driver => ({
                        ...driver,
                        isSelected: driver.driverNumber === this.highestIncomeDriver.driverNumber,
                    }));
                }
            } catch (error) {
                console.error(error);
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isFetchingData = false;
                this.$emit('onFinishLoading');
            }
        },

        // Navigate to reminders page
        navigateToReminders() {
            this.$router.push({ name: 'reminders' });
        },
    },

    // Fetch initial data on component mount
    async mounted() {
        setTimeout(async () => {
            await this.handleQuery();
        }, 100);
    },
};
</script>

<style lang="scss">
.Overview {
    @extend %page;
    @extend %contentWrapper;

    &-header {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        h1 {
            font-size: 1.8rem;
            font-weight: 600;
            margin-bottom: 16px;
            color: var(--color-text-blue-dark);

            @media (max-width: 768px) {
                font-size: 1.4rem;
                margin-bottom: 12px;
            }
        }

        .Overview-dateFilter {
            font-size: 1rem;

            @media (max-width: 768px) {
                width: 100%;
                font-size: 0.9rem;

                // Make the datepicker more mobile-friendly
                :deep(.Datepicker) {
                    width: 100%;

                    input {
                        width: 100%;
                    }
                }
            }
        }

        @media (max-width: 768px) {
            margin-bottom: 16px;
        }
    }

    .Card {
        max-height: 400px;
        overflow-y: auto;

        &.DataTable {
            padding: 0px;
        }

        @media (max-width: 768px) {
            max-height: none; /* Remove max-height on mobile to prevent nested scrolling */
            padding: 8px;
        }
    }

    .OverviewCards {
        @media (max-width: 768px) {
            display: grid;
            grid-template-columns: 1fr; /* Single column on mobile */
            gap: 4px;
            margin-bottom: 16px;
        }
    }

    &-grid {
        margin-top: 24px;
        gap: 4px; /* Restore original gap value for desktop */
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); /* Restore original value */

        @media (min-width: 1400px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media (max-width: 1200px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
            gap: 12px; /* Increased gap only for mobile */
            margin-top: 16px;
        }

        @media (max-width: 480px) {
            /* Ensure content properly fits on very small screens */
            margin-left: -8px;
            margin-right: -8px;
            width: calc(100% + 16px);
        }
    }

    /* Mobile optimizations for specific components */
    @media (max-width: 768px) {
        :deep(.ChartRevenue) {
            margin-bottom: 16px;

            .chart-container {
                height: 250px; /* Smaller chart height on mobile */
            }
        }

        :deep(.BaseDashboardTable) {
            font-size: 0.8rem;

            .table-content {
                overflow-x: auto;

                table {
                    th,
                    td {
                        padding: 6px;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
</style>
