var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', {
    staticClass: "RemindersCard"
  }, [_c('h3', {
    staticClass: "RemindersCard-title"
  }, [_vm._v("Fällige Termine")]), _vm._l(_vm.reminders, function (reminders, i) {
    return _c('div', {
      key: i,
      staticClass: "ReminderSection"
    }, [_c('RowWrap', {
      attrs: {
        "width": "auto"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [reminders.items.length > 0 ? _c('Row', {
            key: -1,
            staticClass: "Row-Header",
            attrs: {
              "variant": "minimal",
              "parentHover": false,
              "items": {
                row: _vm.reminderHeader.map(function (h, i) {
                  return Object.assign({}, h, {
                    name: i === 0 ? h.name + ' ' + reminders.entity : h.name
                  });
                })
              },
              "index": -1
            }
          }) : _vm._e()];
        },
        proxy: true
      }], null, true)
    }, _vm._l(reminders.items, function (reminder, j) {
      return _c('Row', {
        key: j,
        attrs: {
          "variant": 'minimal',
          "hasError": reminder.isDue,
          "hasBadge": reminder.isDue,
          "items": reminder,
          "hasClick": "",
          "index": j
        },
        on: {
          "onClick": function onClick(e) {
            return _vm.handleReminderClick(reminder, e);
          }
        }
      });
    }), 1)], 1);
  }), !_vm.reminders.length ? _c('div', {
    staticClass: "RemindersCard-empty"
  }, [_c('i', {
    staticClass: "ri-calendar-check-line"
  }), _c('p', [_vm._v("Keine anstehenden Termine")])]) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }