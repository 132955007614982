<template>
    <Modal
        :title="editing ? 'Fahrt bearbeiten' : 'Neue Fahrt anlegen'"
        :show="show"
        :action="{ text: 'Speichern', color: 'green', callback: handleSave }"
        :cancel="{ text: 'Abbrechen' }"
        :isLoading="isLoading"
        @onModalClose="$emit('onClose')"
    >
        <div class="TaxiEventModal">
            <div class="TaxiEventModal-section">
                <h3>Grunddaten</h3>
                <div class="TaxiEventModal-row">
                    <div class="TaxiEventModal-field">
                        <label for="event-type">Kategorie</label>
                        <Dropdown
                            id="event-type"
                            :items="eventCategories"
                            :selected="eventData.type"
                            @onItemSelect="item => updateField('type', item)"
                            placeholder="Kategorie wählen"
                        />
                    </div>

                    <div class="TaxiEventModal-field">
                        <label for="event-title">Titel</label>
                        <input
                            id="event-title"
                            type="text"
                            v-model="eventData.title"
                            placeholder="Fahrtbezeichnung"
                            class="Input"
                        />
                    </div>
                </div>
            </div>

            <div class="TaxiEventModal-section">
                <h3>Zeitraum</h3>
                <div class="TaxiEventModal-row">
                    <div class="TaxiEventModal-field">
                        <label for="event-start-date">Datum</label>
                        <input
                            id="event-start-date"
                            type="date"
                            v-model="formattedStartDate"
                            class="Input"
                        />
                    </div>

                    <div class="TaxiEventModal-field">
                        <label for="event-start-time">Uhrzeit Start</label>
                        <input
                            id="event-start-time"
                            type="time"
                            v-model="formattedStartTime"
                            class="Input"
                        />
                    </div>

                    <div class="TaxiEventModal-field">
                        <label for="event-end-time">Uhrzeit Ende</label>
                        <input
                            id="event-end-time"
                            type="time"
                            v-model="formattedEndTime"
                            class="Input"
                        />
                    </div>
                </div>
            </div>

            <div class="TaxiEventModal-section">
                <h3>Fahrt Details</h3>
                <div class="TaxiEventModal-row">
                    <div class="TaxiEventModal-field">
                        <label for="event-driver">Fahrer</label>
                        <Dropdown
                            id="event-driver"
                            :items="drivers"
                            :selected="selectedDriver"
                            @onItemSelect="item => updateField('driver', item.value)"
                            placeholder="Fahrer wählen"
                        />
                    </div>

                    <div class="TaxiEventModal-field">
                        <label for="event-vehicle">Fahrzeug</label>
                        <Dropdown
                            id="event-vehicle"
                            :items="vehicles"
                            :selected="selectedVehicle"
                            @onItemSelect="item => updateField('vehicle', item.value)"
                            placeholder="Fahrzeug wählen"
                        />
                    </div>
                </div>

                <div class="TaxiEventModal-row">
                    <div class="TaxiEventModal-field">
                        <label for="event-start">Startort</label>
                        <input
                            id="event-start"
                            type="text"
                            v-model="eventData.start"
                            placeholder="Abholadresse"
                            class="Input"
                        />
                    </div>

                    <div class="TaxiEventModal-field">
                        <label for="event-destination">Zielort</label>
                        <input
                            id="event-destination"
                            type="text"
                            v-model="eventData.destination"
                            placeholder="Zieladresse"
                            class="Input"
                        />
                    </div>
                </div>
            </div>

            <div class="TaxiEventModal-section">
                <h3>Kundendaten</h3>
                <div class="TaxiEventModal-row">
                    <div class="TaxiEventModal-field">
                        <label for="event-customer">Kunde</label>
                        <input
                            id="event-customer"
                            type="text"
                            v-model="eventData.customer"
                            placeholder="Kundenname"
                            class="Input"
                        />
                    </div>

                    <div class="TaxiEventModal-field">
                        <label for="event-contact">Kontakt</label>
                        <input
                            id="event-contact"
                            type="text"
                            v-model="eventData.contact"
                            placeholder="Telefonnummer"
                            class="Input"
                        />
                    </div>
                </div>

                <div class="TaxiEventModal-field">
                    <label for="event-notes">Notizen</label>
                    <textarea
                        id="event-notes"
                        v-model="eventData.notes"
                        placeholder="Zusätzliche Informationen zur Fahrt"
                        class="Input"
                        rows="3"
                    ></textarea>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@/components/widgets/Modal.vue';
import Dropdown from '@/components/widgets/Dropdown.vue';
import { format, parse } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'TaxiEventModal',
    components: {
        Modal,
        Dropdown,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        drivers: {
            type: Array,
            default: () => [],
        },
        vehicles: {
            type: Array,
            default: () => [],
        },
        event: {
            type: Object,
            default: () => ({}),
        },
        selectedDate: {
            type: Date,
            default: () => new Date(),
        },
    },
    data() {
        return {
            eventData: {
                id: '',
                type: null,
                title: '',
                startDate: null,
                endDate: null,
                driver: '',
                vehicle: '',
                start: '',
                destination: '',
                customer: '',
                contact: '',
                notes: '',
            },
            eventCategories: [
                { id: 'scheduled', value: 'Reguläre Fahrt' },
                { id: 'reservation', value: 'Reservierung' },
                { id: 'airport', value: 'Flughafentransfer' },
                { id: 'maintenance', value: 'Wartung' },
            ],
            formattedStartDate: '',
            formattedStartTime: '',
            formattedEndTime: '',
        };
    },
    computed: {
        editing() {
            return !!this.eventData.id;
        },
        selectedDriver() {
            return this.drivers.find(d => d.value === this.eventData.driver) || null;
        },
        selectedVehicle() {
            return this.vehicles.find(v => v.value === this.eventData.vehicle) || null;
        },
    },
    watch: {
        show(newVal) {
            if (newVal) {
                this.initializeForm();
            }
        },
        event(newEvent) {
            if (newEvent && Object.keys(newEvent).length > 0) {
                this.initializeFromEvent(newEvent);
            }
        },
        formattedStartDate() {
            this.updateDates();
        },
        formattedStartTime() {
            this.updateDates();
        },
        formattedEndTime() {
            this.updateDates();
        },
    },
    methods: {
        initializeForm() {
            if (Object.keys(this.event).length > 0) {
                this.initializeFromEvent(this.event);
            } else {
                // Initialize with default values
                const today = this.selectedDate || new Date();
                const startDate = new Date(today);
                startDate.setHours(Math.max(8, today.getHours()), 0, 0);

                const endDate = new Date(startDate);
                endDate.setMinutes(endDate.getMinutes() + 30);

                this.eventData = {
                    id: '',
                    type: { id: 'scheduled', value: 'Reguläre Fahrt' },
                    title: 'Neue Fahrt',
                    startDate,
                    endDate,
                    driver: '',
                    vehicle: '',
                    start: '',
                    destination: '',
                    customer: '',
                    contact: '',
                    notes: '',
                };

                this.formattedStartDate = format(startDate, 'yyyy-MM-dd');
                this.formattedStartTime = format(startDate, 'HH:mm');
                this.formattedEndTime = format(endDate, 'HH:mm');
            }
        },

        initializeFromEvent(event) {
            this.eventData = { ...event };

            // Format the dates for the form inputs
            if (event.startDate) {
                this.formattedStartDate = format(new Date(event.startDate), 'yyyy-MM-dd');
                this.formattedStartTime = format(new Date(event.startDate), 'HH:mm');
            }

            if (event.endDate) {
                this.formattedEndTime = format(new Date(event.endDate), 'HH:mm');
            } else {
                // Default end time is 30 minutes after start
                const endDate = new Date(event.startDate);
                endDate.setMinutes(endDate.getMinutes() + 30);
                this.formattedEndTime = format(endDate, 'HH:mm');
            }

            // Find the category
            this.eventData.type =
                this.eventCategories.find(c => c.id === event.type) || this.eventCategories[0];
        },

        updateField(field, value) {
            this.eventData[field] = value;
        },

        updateDates() {
            if (!this.formattedStartDate || !this.formattedStartTime) return;

            try {
                // Parse the date and start time
                const dateStr = `${this.formattedStartDate} ${this.formattedStartTime}`;
                const startDate = parse(dateStr, 'yyyy-MM-dd HH:mm', new Date());
                this.eventData.startDate = startDate;

                // Parse the end time
                if (this.formattedEndTime) {
                    const endDate = new Date(startDate);
                    const [endHours, endMinutes] = this.formattedEndTime.split(':').map(Number);
                    endDate.setHours(endHours, endMinutes);

                    // If end is before start, assume it's the next day
                    if (endDate < startDate) {
                        endDate.setDate(endDate.getDate() + 1);
                    }

                    this.eventData.endDate = endDate;
                }
            } catch (error) {
                console.error('Date parsing error:', error);
            }
        },

        handleSave() {
            // Create a copy of the data to emit
            const eventToSave = { ...this.eventData };

            // If it's a new event, generate an ID
            if (!eventToSave.id) {
                eventToSave.id = uuidv4();
            }

            // Ensure type is properly formatted for the component
            if (eventToSave.type && typeof eventToSave.type === 'object') {
                eventToSave.type = eventToSave.type.id;
            }

            this.$emit('onSave', eventToSave);
        },
    },
};
</script>

<style lang="scss">
.TaxiEventModal {
    &-section {
        margin-bottom: 24px;

        h3 {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 16px;
            padding-bottom: 8px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }
    }

    &-row {
        display: flex;
        gap: 16px;
        margin-bottom: 16px;

        @media (max-width: breakpoint(mobile)) {
            flex-direction: column;
            gap: 12px;
        }
    }

    &-field {
        flex: 1;

        label {
            display: block;
            font-weight: 500;
            margin-bottom: 8px;
            font-size: 0.9rem;
        }

        .Input {
            width: 100%;
            padding: 10px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            font-size: 0.95rem;

            &:focus {
                outline: none;
                border-color: var(--color-blue);
                box-shadow: 0 0 0 2px rgba(var(--color-blue-rgb), 0.1);
            }
        }

        textarea.Input {
            resize: vertical;
            min-height: 80px;
        }
    }
}
</style>
