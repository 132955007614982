<template>
    <Card class="RemindersCard">
        <h3 class="RemindersCard-title">Fällige Termine</h3>

        <div v-for="(reminders, i) in reminders" :key="i" class="ReminderSection">
            <RowWrap width="auto">
                <template #header>
                    <Row
                        v-if="reminders.items.length > 0"
                        variant="minimal"
                        class="Row-Header"
                        :parentHover="false"
                        :items="{
                            row: reminderHeader.map((h, i) => ({
                                ...h,
                                name: i === 0 ? h.name + ' ' + reminders.entity : h.name,
                            })),
                        }"
                        :key="-1"
                        :index="-1"
                    />
                </template>
                <Row
                    v-for="(reminder, j) in reminders.items"
                    :variant="'minimal'"
                    :hasError="reminder.isDue"
                    :hasBadge="reminder.isDue"
                    :items="reminder"
                    hasClick
                    @onClick="e => handleReminderClick(reminder, e)"
                    :key="j"
                    :index="j"
                />
            </RowWrap>
        </div>

        <div v-if="!reminders.length" class="RemindersCard-empty">
            <i class="ri-calendar-check-line"></i>
            <p>Keine anstehenden Termine</p>
        </div>
    </Card>
</template>

<script>
import Card from '@/components/Card';
import Row from '@/components/Row';
import RowWrap from '@/components/RowWrap.vue';

export default {
    name: 'RemindersPanel',
    components: {
        Card,
        Row,
        RowWrap,
    },
    props: {
        reminders: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            reminderHeader: [
                { name: 'Erinnerung', value: null },
                {
                    name: '',
                    value: null,
                    props: {
                        style: 'align-items: flex-end;',
                    },
                },
            ],
        };
    },
    methods: {
        handleReminderClick(reminder, e) {
            this.$emit('onReminderClick', reminder, e);
        },
    },
};
</script>

<style lang="scss">
.RemindersCard {
    height: 100%;
padding: 10px;
    &-title {
        font-weight: 600;
        font-size: 1rem;
        margin: 0 0 20px 0;
        padding-bottom: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    &-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px 20px;
        color: #27ae60;

        i {
            font-size: 2rem;
            margin-bottom: 10px;
        }

        p {
            font-size: 1rem;
            text-align: center;
        }
    }

    .ReminderSection {
        margin-bottom: 20px;

        .Row-Header {
            background-color: #f8f9fa;
            font-weight: 600;
        }

        .Row {
            &.has-error {
                background-color: rgba(231, 76, 60, 0.05);
                border-left: 3px solid #e74c3c;
            }

            .ButtonSilence {
                cursor: pointer;
                color: #95a5a6;
                transition: color 0.2s;

                &:hover {
                    color: #e74c3c;
                }
            }
        }
    }
}
</style>
