<template>
    <Card class="AverageMetrics">
        <div class="AverageMetrics-header">
            <h3>Durchschnittliche Einnahmen</h3>
        </div>

        <div class="metric-list">
            <div
                class="metric-item"
                v-for="(item, index) in calculatedAverages"
                :key="index"
                :class="{ 'metric-item--highlighted': index === calculatedAverages.length - 1 }"
            >
                <div class="metric-label">{{ item.label }}</div>
                <div class="metric-value">{{ item.value }}</div>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from '@/components/Card';
import { priceToEuroString } from '@/lib/helper';

export default {
    name: 'AverageMetrics',
    components: {
        Card,
    },
    props: {
        drivers: {
            type: Array,
            default: () => [],
        },
        vehicles: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        // Count visible drivers
        visibleDrivers() {
            return this.drivers.filter(
                driver =>
                    !driver.isSystemDriver && (driver.isVisible || driver.shiftSums.income > 0),
            );
        },

        // Count drivers with revenue
        driversWithRevenue() {
            return this.drivers.filter(
                driver => !driver.isSystemDriver && driver.shiftSums.income > 0,
            );
        },

        // Count active vehicles
        activeVehicles() {
            return this.vehicles.filter(vehicle => vehicle.shiftSums.income > 0);
        },

        // Calculate average income for all drivers
        averageIncomeDrivers() {
            const totalIncome = this.visibleDrivers.reduce(
                (acc, driver) => acc + (driver?.shiftSums?.income || 0),
                0,
            );
            return this.visibleDrivers.length ? totalIncome / this.visibleDrivers.length : 0;
        },

        // Calculate average income for drivers with income
        averageIncomeDriversWithIncome() {
            const totalIncome = this.driversWithRevenue.reduce(
                (acc, driver) => acc + (driver?.shiftSums?.income || 0),
                0,
            );
            return this.driversWithRevenue.length
                ? totalIncome / this.driversWithRevenue.length
                : 0;
        },

        // Calculate average income for all vehicles
        averageIncomeAllVehicles() {
            const totalIncome = this.vehicles.reduce(
                (acc, vehicle) => acc + (vehicle?.shiftSums?.income || 0),
                0,
            );
            return this.vehicles.length ? totalIncome / this.vehicles.length : 0;
        },

        // Calculate average income for active vehicles
        averageIncomeActiveVehicles() {
            const totalIncome = this.activeVehicles.reduce(
                (acc, vehicle) => acc + (vehicle?.shiftSums?.income || 0),
                0,
            );
            return this.activeVehicles.length ? totalIncome / this.activeVehicles.length : 0;
        },

        // Format all the calculated averages for display
        calculatedAverages() {
            return [
                {
                    label: `Fahrer (${this.visibleDrivers.length})`,
                    value: priceToEuroString(this.averageIncomeDrivers),
                },
                {
                    label: `Fahrer mit Umsatz (${this.driversWithRevenue.length})`,
                    value: priceToEuroString(this.averageIncomeDriversWithIncome),
                },
                {
                    label: `Alle Fahrzeuge (${this.vehicles.length})`,
                    value: priceToEuroString(this.averageIncomeAllVehicles),
                },
                {
                    label: `Fahrzeuge mit Umsatz (${this.activeVehicles.length})`,
                    value: priceToEuroString(this.averageIncomeActiveVehicles),
                },
            ];
        },
    },
};
</script>

<style lang="scss">
.AverageMetrics {
    padding: 6px;
    font-size: 0.85rem;

    &-header {
        padding: 5px 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        margin-bottom: 12px;

        h3 {
            margin: 0;
            color: var(--color-text-black);
        }
    }

    .metric-list {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .metric-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.04);
        transition: background-color 0.2s ease;

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.02);
        }

        &--highlighted {
            .metric-value {
                color: var(--color-blue);
                font-weight: 700;
            }
        }
    }

    .metric-label {
        font-size: 0.85rem;
        color: var(--color-gray);
    }

    .metric-value {
        font-size: 0.9rem;
        font-weight: 600;
        color: var(--color-text-black);
        font-feature-settings: 'tnum';
        font-variant-numeric: tabular-nums;
    }
}
</style>
