<template>
    <Card class="SupportCard">
        <h3 class="SupportCard-title">
            <i class="ri-customer-service-2-line"></i>
            Technischer Support
        </h3>

        <div class="SupportCard-content">
            <!-- Phone support section -->
            <div class="SupportCard-section">
                <div class="section-header">
                    <i class="ri-phone-fill section-icon"></i>
                    <h4>Telefon-Support</h4>
                </div>

                <div class="support-contact-row">
                    <div class="phone-display">+49 30 76 00 65 05</div>
                    <a href="tel:+493076006505" class="action-button call-button">
                        <i class="ri-phone-line"></i> Anrufen
                    </a>
                </div>

                <div class="hours-grid">
                    <div class="day-column">
                        <i class="ri-calendar-line"></i> Di
                        <div class="time">14-16 Uhr</div>
                    </div>
                    <div class="day-column">
                        <i class="ri-calendar-line"></i> Do
                        <div class="time">16-19 Uhr</div>
                    </div>
                </div>
            </div>

            <div class="SupportCard-divider">
                <span>ODER</span>
            </div>

            <!-- Email support section -->
            <div class="SupportCard-section">
                <div class="section-header">
                    <i class="ri-mail-fill section-icon"></i>
                    <h4>Email-Support</h4>
                </div>

                <div class="support-contact-row">
                    <div class="email-display">info@taxifusion.de</div>
                    <a href="mailto:info@taxifusion.de" class="action-button email-button">
                        <i class="ri-mail-send-line"></i> Email
                    </a>
                </div>

                <div class="time-response"><i class="ri-time-line"></i> Antwort in 2 Werktagen</div>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from '@/components/Card';

export default {
    name: 'SupportInfo',
    components: {
        Card,
    },
};
</script>

<style lang="scss">
.SupportCard {
    height: 100%;
    padding: 6px;
    font-size: 0.85rem;

    &-title {
        display: flex;
        align-items: center;
        margin: 0 0 6px 0;
        padding: 5px 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        color: var(--color-text-blue-dark, #2c3e50);

        i {
            margin-right: 6px;
            font-size: 1rem;
            color: var(--color-blue);
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    &-section {
        background-color: rgba(0, 0, 0, 0.01);
        border-radius: 4px;
        padding: 6px;

        .section-header {
            display: flex;
            align-items: center;
            margin-bottom: 6px;

            .section-icon {
                font-size: 1rem;
                color: var(--color-blue);
                margin-right: 6px;
            }

            h4 {
                font-size: 0.85rem;
                font-weight: 600;
                margin: 0;
                color: var(--color-text-black);
            }
        }
    }

    .support-contact-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 6px;
    }

    .phone-display,
    .email-display {
        font-size: 0.9rem;
        font-weight: 600;
        color: var(--color-text-blue-dark);
        font-feature-settings: 'tnum';
        font-variant-numeric: tabular-nums;
    }

    .action-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-text-blue-dark, #2c3e50);
        color: white;
        padding: 3px 8px;
        border-radius: 4px;
        font-size: 0.75rem;
        font-weight: 500;
        text-decoration: none;
        transition: background-color 0.2s;

        i {
            margin-right: 3px;
            font-size: 0.8rem;
        }

        &:hover {
            background-color: var(--color-blue);
        }
    }

    .hours-grid {
        display: flex;
        text-align: center;
        width: 100%;

        .day-column {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 0.8rem;
            padding: 4px 0;
            color: var(--color-text-black);

            i {
                margin-right: 3px;
                font-size: 0.85rem;
                color: var(--color-text-gray);
            }

            .time {
                font-weight: 600;
                margin-top: 2px;
            }
        }
    }

    .time-response {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        color: var(--color-text-black);

        i {
            margin-right: 4px;
            font-size: 0.85rem;
            color: var(--color-text-gray);
        }
    }

    &-divider {
        position: relative;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.05);
        margin: 3px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            position: absolute;
            background-color: white;
            padding: 0 8px;
            font-size: 0.7rem;
            font-weight: 500;
            color: var(--color-text-gray);
            text-transform: uppercase;
        }
    }

    @media (max-width: 480px) {
        .action-button {
            padding: 3px 6px;

            span {
                display: none;
            }

            i {
                margin-right: 0;
            }
        }
    }
}
</style>
