var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', {
    staticClass: "CalendarWidget TaxiCalendar"
  }, [_c('div', {
    staticClass: "TaxiCalendar-header"
  }, [_c('h2', [_vm._v("Taxi Einsatzplanung")]), _c('div', {
    staticClass: "TaxiCalendar-controls"
  }, [_c('div', {
    staticClass: "TaxiCalendar-legends"
  }, [_c('div', {
    staticClass: "LegendItem"
  }, [_c('span', {
    staticClass: "ColorDot reservation"
  }), _c('span', [_vm._v("Reservierung")])]), _c('div', {
    staticClass: "LegendItem"
  }, [_c('span', {
    staticClass: "ColorDot airport"
  }), _c('span', [_vm._v("Flughafen")])]), _c('div', {
    staticClass: "LegendItem"
  }, [_c('span', {
    staticClass: "ColorDot scheduled"
  }), _c('span', [_vm._v("Regelmäßige Fahrt")])]), _c('div', {
    staticClass: "LegendItem"
  }, [_c('span', {
    staticClass: "ColorDot maintenance"
  }), _c('span', [_vm._v("Wartung")])])]), _c('div', {
    staticClass: "TaxiCalendar-actions"
  }, [_c('Button', {
    attrs: {
      "size": "small",
      "variant": "outline",
      "icon": "plus"
    },
    on: {
      "onClick": _vm.handleAddEvent
    }
  }, [_vm._v(" Neuer Eintrag ")]), _c('Button', {
    attrs: {
      "size": "small",
      "variant": "outline",
      "icon": "th"
    },
    on: {
      "onClick": _vm.handleViewMode
    }
  }, [_vm._v(" " + _vm._s(_vm.viewMode === 'month' ? 'Wochenansicht' : 'Monatsansicht') + " ")])], 1)])]), _c('div', {
    staticClass: "TaxiCalendar-filters"
  }, [_c('div', {
    staticClass: "FilterGroup"
  }, [_c('label', [_vm._v("Fahrer:")]), _c('Dropdown', {
    attrs: {
      "size": "small",
      "variant": "minimal",
      "items": _vm.drivers,
      "selected": _vm.selectedDriver,
      "placeholder": "Alle Fahrer"
    },
    on: {
      "onItemSelect": _vm.handleDriverSelect
    }
  })], 1), _c('div', {
    staticClass: "FilterGroup"
  }, [_c('label', [_vm._v("Fahrzeug:")]), _c('Dropdown', {
    attrs: {
      "size": "small",
      "variant": "minimal",
      "items": _vm.vehicles,
      "selected": _vm.selectedVehicle,
      "placeholder": "Alle Fahrzeuge"
    },
    on: {
      "onItemSelect": _vm.handleVehicleSelect
    }
  })], 1), _c('div', {
    staticClass: "FilterGroup"
  }, [_c('label', [_vm._v("Kategorie:")]), _c('Dropdown', {
    attrs: {
      "size": "small",
      "variant": "minimal",
      "items": _vm.categories,
      "selected": _vm.selectedCategory,
      "placeholder": "Alle Kategorien"
    },
    on: {
      "onItemSelect": _vm.handleCategorySelect
    }
  })], 1)]), _c('calendar-view', {
    staticClass: "theme-default taxi-calendar-view",
    attrs: {
      "enableDragDrop": true,
      "enable-date-selection": true,
      "items": _vm.filteredEvents,
      "showDate": _vm.dateToShow,
      "selection-start": _vm.selectionStart,
      "selection-end": _vm.selectionEnd,
      "displayPeriodUom": _vm.viewMode,
      "time-from-string": _vm.timeFromString,
      "time-to-string": _vm.timeToString,
      "locale": "de"
    },
    on: {
      "date-selection-finish": _vm.handleDateSelection,
      "date-selection-start": _vm.setSelection,
      "date-selection": _vm.setSelection,
      "click-date": _vm.handleClickDate,
      "click-item": _vm.handleClickItem,
      "drop-on-date": _vm.handleDropOnDate
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(t) {
        return _c('calendar-view-header', {
          attrs: {
            "header-props": t.headerProps
          },
          on: {
            "input": _vm.setShowDate
          }
        });
      }
    }])
  }), _vm.selectedEvent ? _c('div', {
    staticClass: "TaxiCalendar-info"
  }, [_c('div', {
    staticClass: "InfoCard"
  }, [_c('div', {
    staticClass: "InfoHeader"
  }, [_c('h3', [_vm._v(_vm._s(_vm.selectedEvent.title))]), _c('button', {
    staticClass: "CloseButton",
    on: {
      "click": function click($event) {
        _vm.selectedEvent = null;
      }
    }
  }, [_vm._v("×")])]), _c('div', {
    staticClass: "InfoDetails"
  }, [_c('div', {
    staticClass: "InfoRow"
  }, [_c('span', {
    staticClass: "InfoLabel"
  }, [_vm._v("Datum:")]), _c('span', {
    staticClass: "InfoValue"
  }, [_vm._v(_vm._s(_vm.formatEventDate(_vm.selectedEvent)))])]), _c('div', {
    staticClass: "InfoRow"
  }, [_c('span', {
    staticClass: "InfoLabel"
  }, [_vm._v("Zeit:")]), _c('span', {
    staticClass: "InfoValue"
  }, [_vm._v(_vm._s(_vm.formatEventTime(_vm.selectedEvent)))])]), _c('div', {
    staticClass: "InfoRow"
  }, [_c('span', {
    staticClass: "InfoLabel"
  }, [_vm._v("Fahrer:")]), _c('span', {
    staticClass: "InfoValue"
  }, [_vm._v(_vm._s(_vm.selectedEvent.driver || '-'))])]), _c('div', {
    staticClass: "InfoRow"
  }, [_c('span', {
    staticClass: "InfoLabel"
  }, [_vm._v("Fahrzeug:")]), _c('span', {
    staticClass: "InfoValue"
  }, [_vm._v(_vm._s(_vm.selectedEvent.vehicle || '-'))])]), _c('div', {
    staticClass: "InfoRow"
  }, [_c('span', {
    staticClass: "InfoLabel"
  }, [_vm._v("Start:")]), _c('span', {
    staticClass: "InfoValue"
  }, [_vm._v(_vm._s(_vm.selectedEvent.start || '-'))])]), _c('div', {
    staticClass: "InfoRow"
  }, [_c('span', {
    staticClass: "InfoLabel"
  }, [_vm._v("Ziel:")]), _c('span', {
    staticClass: "InfoValue"
  }, [_vm._v(_vm._s(_vm.selectedEvent.destination || '-'))])]), _c('div', {
    staticClass: "InfoRow"
  }, [_c('span', {
    staticClass: "InfoLabel"
  }, [_vm._v("Kunde:")]), _c('span', {
    staticClass: "InfoValue"
  }, [_vm._v(_vm._s(_vm.selectedEvent.customer || '-'))])]), _c('div', {
    staticClass: "InfoRow"
  }, [_c('span', {
    staticClass: "InfoLabel"
  }, [_vm._v("Kontakt:")]), _c('span', {
    staticClass: "InfoValue"
  }, [_vm._v(_vm._s(_vm.selectedEvent.contact || '-'))])]), _vm.selectedEvent.notes ? _c('div', {
    staticClass: "InfoRow"
  }, [_c('span', {
    staticClass: "InfoLabel"
  }, [_vm._v("Notizen:")]), _c('span', {
    staticClass: "InfoValue"
  }, [_vm._v(_vm._s(_vm.selectedEvent.notes))])]) : _vm._e()]), _c('div', {
    staticClass: "InfoActions"
  }, [_c('Button', {
    attrs: {
      "size": "small",
      "variant": "outline",
      "icon": "edit"
    },
    on: {
      "onClick": _vm.handleEditEvent
    }
  }, [_vm._v(" Bearbeiten ")]), _c('Button', {
    attrs: {
      "size": "small",
      "variant": "outline",
      "color": "red",
      "icon": "times"
    },
    on: {
      "onClick": _vm.handleCancelEvent
    }
  }, [_vm._v(" Stornieren ")])], 1)])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }