var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', {
    staticClass: "AverageMetrics"
  }, [_c('div', {
    staticClass: "AverageMetrics-header"
  }, [_c('h3', [_vm._v("Durchschnittliche Einnahmen")])]), _c('div', {
    staticClass: "metric-list"
  }, _vm._l(_vm.calculatedAverages, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "metric-item",
      class: {
        'metric-item--highlighted': index === _vm.calculatedAverages.length - 1
      }
    }, [_c('div', {
      staticClass: "metric-label"
    }, [_vm._v(_vm._s(item.label))]), _c('div', {
      staticClass: "metric-value"
    }, [_vm._v(_vm._s(item.value))])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }