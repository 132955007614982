<template>
    <Transition name="fade">
        <div v-if="show" class="LoadingOverlay">
            <div class="LoadingOverlay-content">
                <div class="LoadingOverlay-spinner"></div>
                <p>{{ message }}</p>
            </div>
        </div>
    </Transition>
</template>

<script>
export default {
    name: 'LoadingOverlay',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        message: {
            type: String,
            default: 'Laden...',
        },
    },
};
</script>

<style lang="scss">
.LoadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    pointer-events: none;

    &-content {
        text-align: center;
        animation: fadeInScale 0.5s ease-out;
    }

    &-spinner {
        width: 50px;
        height: 50px;
        border: 5px solid rgba(0, 0, 0, 0.1);
        border-top-color: var(--color-green-dark);
        border-radius: 50%;
        animation: spin 1s infinite linear;
        margin: 0 auto 20px;
    }

    p {
        font-size: 1.2rem;
        color: var(--color-gray-dark);
        margin-top: 10px;
    }

    @keyframes fadeInScale {
        0% {
            opacity: 0;
            transform: scale(0.8);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
