var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', {
    staticClass: "OverviewCard RevenueTarget"
  }, [_c('div', {
    staticClass: "metric-content"
  }, [_c('div', {
    staticClass: "metric-icon",
    class: _vm.getProgressClass()
  }, [_c('i', {
    staticClass: "ri-funds-line"
  })]), _c('div', {
    staticClass: "metric-details"
  }, [_c('h3', [_vm._v("Monatliches Ziel")]), _c('div', {
    staticClass: "metric-value"
  }, [_vm._v(_vm._s(_vm.priceToEuroString(_vm.monthlyIncome)))]), _c('div', {
    staticClass: "metric-change",
    class: _vm.getProgressClass()
  }, [_c('span', [_vm._v(_vm._s(_vm.percentageToString(_vm.monthlyIncome / (_vm.targetIncome * 100))) + " erreicht")]), _c('i', {
    staticClass: "trend-icon ri-arrow-up-line"
  })])]), _c('div', {
    staticClass: "settings-icon",
    on: {
      "click": function click($event) {
        return _vm.$emit('onSettingsClick');
      }
    }
  }, [_c('i', {
    staticClass: "ri-settings-line"
  })])]), _c('div', {
    staticClass: "progress-container"
  }, [_c('div', {
    staticClass: "progress-bar",
    style: {
      width: "".concat(_vm.getProgressPercentage(), "%")
    }
  }), _c('div', {
    staticClass: "progress-target"
  }, [_c('span', [_vm._v("Ziel: " + _vm._s(_vm.priceToEuroString(_vm.targetIncome * 100)))])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }