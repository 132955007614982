var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ChartV2', {
    attrs: {
      "halfHeight": "",
      "color": "green",
      "type": "bar",
      "title": " ",
      "values": _vm.chartData
    },
    on: {
      "onLeave": function onLeave($event) {
        return _vm.$emit('onLeave');
      }
    }
  }, [_c('RevenueSummary', {
    attrs: {
      "currentRevenue": _vm.yearlyIncome,
      "comparisonRevenue": _vm.prevYearIncome,
      "currentStartDate": _vm.startDate,
      "currentEndDate": _vm.endDate,
      "comparisonStartDate": _vm.subMonths(_vm.startDate, _vm.comparisonMonthly ? 1 : 12),
      "comparisonEndDate": _vm.subMonths(_vm.endDate, _vm.comparisonMonthly ? 1 : 12)
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }