var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "InactiveVehiclesCard"
  }, [_vm.formattedVehicles.length > 0 ? _c('BaseDashboardTable', {
    staticClass: "inactive-vehicles-table",
    attrs: {
      "columns": _vm.columns,
      "items": _vm.formattedVehicles,
      "sort": _vm.sortState,
      "isClickable": false,
      "empty-message": 'Keine inaktiven Fahrzeuge',
      "has-search": false,
      "showItemCounter": false
    },
    on: {
      "sort-changed": _vm.handleSortChanged,
      "row-hover": function rowHover(args) {
        return _vm.$emit('onRowHover', args);
      },
      "row-leave": function rowLeave(args) {
        return _vm.$emit('onRowLeave', args);
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h3', {}, [_vm._v(_vm._s(_vm.title))])];
      },
      proxy: true
    }], null, false, 547223990)
  }) : _c('div', {
    staticClass: "InactiveVehiclesCard-empty"
  }, [_c('i', {
    staticClass: "ri-check-circle-line"
  }), _c('p', [_vm._v("Alle Fahrzeuge aktiv")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }