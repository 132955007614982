<template>
    <BaseDashboardTable
        class="VehicleTable compact-mode"
        :columns="tableColumns"
        :items="tableItems"
        :sort="localSortState"
        :selectedFn="item => item.isSelected"
        :isClickable="true"
        :has-search="false"
        :showItemCounter="false"
        emptyMessage="Keine Daten"
        @sort-changed="handleSortChange"
        @row-click="handleVehicleClick"
        @row-hover="handleRowHover"
        @row-leave="handleRowLeave"
    >
        <template #title>
            <h3>Fahrzeuge</h3>
        </template>

        <!-- Custom column renderers -->
        <template #column-licenseNumber="{ item }">
            <span class="license-number">{{ item.licenseNumber }}</span>
        </template>

        <template #column-income="{ item }">
            {{ formatIncome(item.income) }}
        </template>

        <template #column-euroPerKm="{ item }">
            {{ formatEuroPerKm(item.euroPerKm) }}
        </template>
    </BaseDashboardTable>
</template>

<script>
import BaseDashboardTable from '@/components/BaseDashboardTable.vue';
import { priceToEuroString } from '@/lib/helper';

export default {
    name: 'VehiclesTable',
    components: {
        BaseDashboardTable,
    },
    props: {
        vehicles: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            localSortState: {
                column: 'income',
                ascending: false,
            },
        };
    },
    computed: {
        // Define table columns
        tableColumns() {
            return [
                {
                    key: 'licenseNumber',
                    label: 'Kennzeichen',
                    sortable: true,
                },
                {
                    key: 'income',
                    label: '€',
                    sortable: true,
                },
                {
                    key: 'euroPerKm',
                    label: '€/KM',
                    sortable: true,
                },
            ];
        },

        // Transform vehicles array to table items with local sorting
        tableItems() {
            const items = this.vehicles.map(vehicle => {
                // Find income and euro/km from vehicle.row
                const incomeEntry = vehicle.row.find(e => e.name === 'Einnahmen');
                const euroPerKmEntry = vehicle.row.find(e => e.name === 'EURO/KM');
                const licenseEntry = vehicle.row.find(e => e.name === 'Fahrzeug');

                return {
                    id: vehicle.licenseNumber,
                    licenseNumber: licenseEntry?.value || vehicle.licenseNumber,
                    income: incomeEntry?.rawValue || 0,
                    euroPerKm: euroPerKmEntry?.rawValue || 0,
                    isSelected: vehicle.isSelected,
                    // Keep the original vehicle object for reference
                    original: vehicle,
                };
            });

            // Apply local sorting
            return this.sortVehicleItems(items);
        },
    },
    methods: {
        // Format income value - simplified for compact display
        formatIncome(value) {
            const formatted =
                this.findVehicleByIncome(value)?.row.find(e => e.name === 'Einnahmen')?.value ||
                '0 €';
            // Return only the numeric part with € symbol, ensure consistent spacing
            return formatted.replace(/\s+/g, '');
        },

        // Format euro per km value - simplified for compact display
        formatEuroPerKm(value) {
            const formatted =
                this.findVehicleByEuroPerKm(value)?.row.find(e => e.name === 'EURO/KM')?.value ||
                '0';
            // Ensure consistent spacing
            return formatted.startsWith('≈') ? formatted : `≈${formatted}`;
        },

        // Find vehicle by income value
        findVehicleByIncome(income) {
            return this.vehicles.find(vehicle => {
                const entry = vehicle.row.find(e => e.name === 'Einnahmen');
                return entry?.rawValue === income;
            });
        },

        // Find vehicle by euro per km value
        findVehicleByEuroPerKm(euroPerKm) {
            return this.vehicles.find(vehicle => {
                const entry = vehicle.row.find(e => e.name === 'EURO/KM');
                return entry?.rawValue === euroPerKm;
            });
        },

        // Sort vehicle items locally
        sortVehicleItems(items) {
            return [...items].sort((a, b) => {
                const valueA = a[this.localSortState.column];
                const valueB = b[this.localSortState.column];

                if (this.localSortState.ascending) {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        },

        // Handle sort change
        handleSortChange(sortData) {
            // Update local state only, no need to emit to parent
            this.localSortState = sortData;
        },

        // Handle vehicle click
        handleVehicleClick({ item }) {
            this.$emit('onVehicleClick', { item: item.original });
        },

        // Handle row hover
        handleRowHover({ item }) {
            this.$emit('onRowHover', item.original);
        },

        // Handle row leave
        handleRowLeave({ item }) {
            this.$emit('onRowLeave', item.original);
        },

        // Helper function to format price
        formatPrice(value) {
            return priceToEuroString(value);
        },
    },
};
</script>

<style lang="scss"></style>
