var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', {
    staticClass: "OverviewCard StatusCard",
    attrs: {
      "variant": "warning"
    }
  }, [_c('div', {
    staticClass: "metric-content"
  }, [_c('div', {
    staticClass: "metric-icon",
    class: _vm.reminders.length > 0 ? 'negative' : 'positive'
  }, [_c('i', {
    staticClass: "ri-calendar-event-fill"
  })]), _c('div', {
    staticClass: "metric-details"
  }, [_c('h3', [_vm._v("Fällige Termine")]), _c('div', {
    staticClass: "metric-value"
  }, [_vm._v(_vm._s(_vm.reminders.length))]), _c('div', {
    staticClass: "metric-change",
    class: _vm.reminders.length > 0 ? 'negative' : 'positive'
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }