var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', {
    staticClass: "PerformanceCard"
  }, [_c('h3', {
    staticClass: "PerformanceCard-title"
  }, [_vm._v(_vm._s(_vm.name))]), _c('div', {
    staticClass: "PerformanceCard-content"
  }, [_c('div', {
    staticClass: "PerformanceCard-main-kpi"
  }, [_c('div', {
    staticClass: "kpi-label"
  }, [_vm._v("Leistung")]), _c('div', {
    staticClass: "kpi-value-container"
  }, [_c('div', {
    staticClass: "kpi-value-wrapper",
    class: ["performance-".concat(_vm.performanceData.class)]
  }, [_c('span', {
    staticClass: "kpi-value"
  }, [_vm._v(_vm._s(_vm.performanceData.euroPerKm))]), _c('span', {
    staticClass: "kpi-unit"
  }, [_vm._v("€/Km")])]), _c('div', {
    staticClass: "kpi-status",
    class: ["status-".concat(_vm.performanceData.class)]
  }, [_vm._v(" " + _vm._s(_vm.performanceData.ratingText) + " ")])]), _c('div', {
    staticClass: "custom-progress-bar"
  }, [_c('div', {
    staticClass: "progress-fill",
    class: ["progress-".concat(_vm.performanceData.class)],
    style: {
      width: "".concat(_vm.performanceData.percentage, "%")
    }
  }), _c('div', {
    staticClass: "progress-markers"
  }, [_c('div', {
    staticClass: "marker marker-low"
  }), _c('div', {
    staticClass: "marker marker-mid"
  }), _c('div', {
    staticClass: "marker marker-high"
  })])])]), _c('div', {
    staticClass: "metrics-layout"
  }, [_c('div', {
    staticClass: "metrics-group"
  }, [_c('div', {
    staticClass: "metrics-group-title"
  }, [_vm._v("Finanzen")]), _c('div', {
    staticClass: "metrics-row"
  }, [_c('span', {
    staticClass: "metric-label"
  }, [_vm._v("Einnahmen:")]), _c('strong', {
    staticClass: "metric-value"
  }, [_vm._v(_vm._s(_vm.priceToEuroString(_vm.item.income || 0)))])])]), _c('div', {
    staticClass: "metrics-group"
  }, [_c('div', {
    staticClass: "metrics-group-title"
  }, [_vm._v("Strecke")]), _c('div', {
    staticClass: "metrics-row"
  }, [_c('span', {
    staticClass: "metric-label"
  }, [_vm._v("Gesamt-Km:")]), _c('strong', {
    staticClass: "metric-value"
  }, [_vm._v(_vm._s(_vm.distanceToString(_vm.item.totalDistance || 0)))])]), _c('div', {
    staticClass: "metrics-row"
  }, [_c('span', {
    staticClass: "metric-label"
  }, [_vm._v("Besetzt-Km:")]), _c('strong', {
    staticClass: "metric-value"
  }, [_vm._v(_vm._s(_vm.distanceToString(_vm.item.hiredDistance || 0)))])]), _c('div', {
    staticClass: "metrics-row"
  }, [_c('span', {
    staticClass: "metric-label"
  }, [_vm._v("Besetzt-%:")]), _c('strong', {
    staticClass: "metric-value"
  }, [_vm._v(" " + _vm._s(_vm.percentageToString((_vm.item.hiredDistance || 0) / (_vm.item.totalDistance || 1))) + " ")])])]), _c('div', {
    staticClass: "metrics-group"
  }, [_c('div', {
    staticClass: "metrics-group-title"
  }, [_vm._v("Zeit")]), _c('div', {
    staticClass: "metrics-row"
  }, [_c('span', {
    staticClass: "metric-label"
  }, [_vm._v("Schichtzeit:")]), _c('strong', {
    staticClass: "metric-value"
  }, [_vm._v(_vm._s(_vm.timeToString(_vm.item.shiftTime || 0)))])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }