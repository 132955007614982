var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    attrs: {
      "title": _vm.editing ? 'Fahrt bearbeiten' : 'Neue Fahrt anlegen',
      "show": _vm.show,
      "action": {
        text: 'Speichern',
        color: 'green',
        callback: _vm.handleSave
      },
      "cancel": {
        text: 'Abbrechen'
      },
      "isLoading": _vm.isLoading
    },
    on: {
      "onModalClose": function onModalClose($event) {
        return _vm.$emit('onClose');
      }
    }
  }, [_c('div', {
    staticClass: "TaxiEventModal"
  }, [_c('div', {
    staticClass: "TaxiEventModal-section"
  }, [_c('h3', [_vm._v("Grunddaten")]), _c('div', {
    staticClass: "TaxiEventModal-row"
  }, [_c('div', {
    staticClass: "TaxiEventModal-field"
  }, [_c('label', {
    attrs: {
      "for": "event-type"
    }
  }, [_vm._v("Kategorie")]), _c('Dropdown', {
    attrs: {
      "id": "event-type",
      "items": _vm.eventCategories,
      "selected": _vm.eventData.type,
      "placeholder": "Kategorie wählen"
    },
    on: {
      "onItemSelect": function onItemSelect(item) {
        return _vm.updateField('type', item);
      }
    }
  })], 1), _c('div', {
    staticClass: "TaxiEventModal-field"
  }, [_c('label', {
    attrs: {
      "for": "event-title"
    }
  }, [_vm._v("Titel")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.eventData.title,
      expression: "eventData.title"
    }],
    staticClass: "Input",
    attrs: {
      "id": "event-title",
      "type": "text",
      "placeholder": "Fahrtbezeichnung"
    },
    domProps: {
      "value": _vm.eventData.title
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.eventData, "title", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "TaxiEventModal-section"
  }, [_c('h3', [_vm._v("Zeitraum")]), _c('div', {
    staticClass: "TaxiEventModal-row"
  }, [_c('div', {
    staticClass: "TaxiEventModal-field"
  }, [_c('label', {
    attrs: {
      "for": "event-start-date"
    }
  }, [_vm._v("Datum")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formattedStartDate,
      expression: "formattedStartDate"
    }],
    staticClass: "Input",
    attrs: {
      "id": "event-start-date",
      "type": "date"
    },
    domProps: {
      "value": _vm.formattedStartDate
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.formattedStartDate = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "TaxiEventModal-field"
  }, [_c('label', {
    attrs: {
      "for": "event-start-time"
    }
  }, [_vm._v("Uhrzeit Start")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formattedStartTime,
      expression: "formattedStartTime"
    }],
    staticClass: "Input",
    attrs: {
      "id": "event-start-time",
      "type": "time"
    },
    domProps: {
      "value": _vm.formattedStartTime
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.formattedStartTime = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "TaxiEventModal-field"
  }, [_c('label', {
    attrs: {
      "for": "event-end-time"
    }
  }, [_vm._v("Uhrzeit Ende")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formattedEndTime,
      expression: "formattedEndTime"
    }],
    staticClass: "Input",
    attrs: {
      "id": "event-end-time",
      "type": "time"
    },
    domProps: {
      "value": _vm.formattedEndTime
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.formattedEndTime = $event.target.value;
      }
    }
  })])])]), _c('div', {
    staticClass: "TaxiEventModal-section"
  }, [_c('h3', [_vm._v("Fahrt Details")]), _c('div', {
    staticClass: "TaxiEventModal-row"
  }, [_c('div', {
    staticClass: "TaxiEventModal-field"
  }, [_c('label', {
    attrs: {
      "for": "event-driver"
    }
  }, [_vm._v("Fahrer")]), _c('Dropdown', {
    attrs: {
      "id": "event-driver",
      "items": _vm.drivers,
      "selected": _vm.selectedDriver,
      "placeholder": "Fahrer wählen"
    },
    on: {
      "onItemSelect": function onItemSelect(item) {
        return _vm.updateField('driver', item.value);
      }
    }
  })], 1), _c('div', {
    staticClass: "TaxiEventModal-field"
  }, [_c('label', {
    attrs: {
      "for": "event-vehicle"
    }
  }, [_vm._v("Fahrzeug")]), _c('Dropdown', {
    attrs: {
      "id": "event-vehicle",
      "items": _vm.vehicles,
      "selected": _vm.selectedVehicle,
      "placeholder": "Fahrzeug wählen"
    },
    on: {
      "onItemSelect": function onItemSelect(item) {
        return _vm.updateField('vehicle', item.value);
      }
    }
  })], 1)]), _c('div', {
    staticClass: "TaxiEventModal-row"
  }, [_c('div', {
    staticClass: "TaxiEventModal-field"
  }, [_c('label', {
    attrs: {
      "for": "event-start"
    }
  }, [_vm._v("Startort")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.eventData.start,
      expression: "eventData.start"
    }],
    staticClass: "Input",
    attrs: {
      "id": "event-start",
      "type": "text",
      "placeholder": "Abholadresse"
    },
    domProps: {
      "value": _vm.eventData.start
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.eventData, "start", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "TaxiEventModal-field"
  }, [_c('label', {
    attrs: {
      "for": "event-destination"
    }
  }, [_vm._v("Zielort")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.eventData.destination,
      expression: "eventData.destination"
    }],
    staticClass: "Input",
    attrs: {
      "id": "event-destination",
      "type": "text",
      "placeholder": "Zieladresse"
    },
    domProps: {
      "value": _vm.eventData.destination
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.eventData, "destination", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "TaxiEventModal-section"
  }, [_c('h3', [_vm._v("Kundendaten")]), _c('div', {
    staticClass: "TaxiEventModal-row"
  }, [_c('div', {
    staticClass: "TaxiEventModal-field"
  }, [_c('label', {
    attrs: {
      "for": "event-customer"
    }
  }, [_vm._v("Kunde")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.eventData.customer,
      expression: "eventData.customer"
    }],
    staticClass: "Input",
    attrs: {
      "id": "event-customer",
      "type": "text",
      "placeholder": "Kundenname"
    },
    domProps: {
      "value": _vm.eventData.customer
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.eventData, "customer", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "TaxiEventModal-field"
  }, [_c('label', {
    attrs: {
      "for": "event-contact"
    }
  }, [_vm._v("Kontakt")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.eventData.contact,
      expression: "eventData.contact"
    }],
    staticClass: "Input",
    attrs: {
      "id": "event-contact",
      "type": "text",
      "placeholder": "Telefonnummer"
    },
    domProps: {
      "value": _vm.eventData.contact
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.eventData, "contact", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "TaxiEventModal-field"
  }, [_c('label', {
    attrs: {
      "for": "event-notes"
    }
  }, [_vm._v("Notizen")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.eventData.notes,
      expression: "eventData.notes"
    }],
    staticClass: "Input",
    attrs: {
      "id": "event-notes",
      "placeholder": "Zusätzliche Informationen zur Fahrt",
      "rows": "3"
    },
    domProps: {
      "value": _vm.eventData.notes
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.eventData, "notes", $event.target.value);
      }
    }
  })])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }