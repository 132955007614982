<template>
    <ChartV2
        halfHeight
        color="green"
        type="bar"
        title=" "
        :values="chartData"
        @onLeave="$emit('onLeave')"
    >
        <RevenueSummary
            :currentRevenue="yearlyIncome"
            :comparisonRevenue="prevYearIncome"
            :currentStartDate="startDate"
            :currentEndDate="endDate"
            :comparisonStartDate="subMonths(startDate, comparisonMonthly ? 1 : 12)"
            :comparisonEndDate="subMonths(endDate, comparisonMonthly ? 1 : 12)"
        />
    </ChartV2>
</template>

<script>
import Card from '@/components/Card';
import ChartV2 from '@/components/widgets/ChartV2';
import RevenueSummary from '@/components/RevenueChartInfo.vue';
import { subMonths } from 'date-fns';

export default {
    name: 'RevenueChart',
    components: {
        Card,
        ChartV2,
        RevenueSummary,
    },
    props: {
        chartData: {
            type: Array,
            required: true,
        },
        yearlyIncome: {
            type: Number,
            required: true,
        },
        prevYearIncome: {
            type: Number,
            required: true,
        },
        startDate: {
            type: Date,
            required: true,
        },
        endDate: {
            type: Date,
            required: true,
        },
        comparisonMonthly: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        subMonths,
    },
};
</script>

<style lang="scss">
.RevenueChartCard {
    margin-bottom: 24px;

    .chart-container {
        padding: 16px 8px;
    }
}
</style>
